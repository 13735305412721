import axios from 'axios';
import eventEditionManager from '../User/EventEditionManager';

export default function () {
  return {
    get(contact) {
      const eventEdition = eventEditionManager.get();
      return axios.get(`/${eventEdition.slug}/meeting-invites?contact=${contact}`);
    },
  };
}
