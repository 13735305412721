<template>
  <BaseModal
    :title="__('conference_sessions.preview_session.title')"
    ref="modal"
    height="tall"
    startOpen
  >
    <template v-if="conference">
      <div class="base-modal__container--wide">
        <img class="base-modal__body-image"
          v-if="conference.card_image"
          :src="conference.card_image"
          :alt="conference.title"
        />
        <div class="conference-session-row__countdown" v-if="conference.starting_soon">
          <b>
            {{ __("conference_sessions.row.starting_soon") }}
            {{ conference.starting_in }}
          </b>
        </div>
      </div>

      <div class="base-modal__body-row">
        <div class="conference-session-row__tracks_container">
          <span
            v-for="track in conference.tracks"
            :style="`background-color:` + track.color + `;`"
            class="conference-session-row__track"
            :key="track.id"
          >
            <b> {{ track.label }} </b>
          </span>
        </div>
      </div>

      <h2>{{ conference.title }}</h2>

      <div
        v-if="conference.duration_for_humans && conference.location_name"
        class="base-modal__body-row"
      >
        <div class="conference-session-row__details-container">
          <span class="conference-session-row__detail">
            <i class="fa-regular fa-calendar"></i>&nbsp;
            <strong>{{ conference.formatted_start_date }}</strong>
          </span>
          <span class="conference-session-row__detail">
            <i class="far fa-clock"></i>&nbsp;
            <strong>{{ conference.formatted_start_time }}</strong
            >&nbsp;
            <small>({{ conference.duration_for_humans }})</small>
          </span>
          <span class="conference-session-row__detail">
            <i class="fa-regular fa-location-dot"></i>&nbsp;
            <strong>{{ conference.location_name }}</strong>
            <span v-if="conference.location_details?.trim() !== ''">
              ({{ conference.location_details }})
            </span>
          </span>
        </div>
      </div>

      <div class="base-modal__body-row" v-html="conference.short_description">
      </div>

      <template v-if="conference.speakers?.length > 0">
        <div class="base-modal__body-row">
          <ModalExpandable
            :title="__('conference_sessions.preview_session.speakers')"
            :collapsed="true"
          >
            <div
              class="conference-session-row__speaker-container"
              style="grid-template-columns: repeat(auto-fit, 220px)"
            >
              <ContactRow
                v-for="contact in conference.speakers"
                :key="contact.id"
                :contact="contact"
                :linkable="!isIframed"
              />
            </div>
          </ModalExpandable>
        </div>
      </template>
    </template>

    <template v-if="!conference.id && !errored">
      <h1 class="ball-scale-ripple-multiple">
        <div></div> <div></div> <div></div>
      </h1>
    </template>

    <template #footer v-if="conference && !isIframed">
      <div class="button-group">
        <button v-if="loading" class="button button--loading" />
        <!-- if on demand, or online AND is running now? -->
        <a
          class="button button--primary"
          v-else-if="conference.policy.can_enter"
          :href="`${conferenceUrl}`"
        >
          {{ __("conference_sessions.row.buttons.watch_now") }}
        </a>
        <button v-else-if="conference.policy.is_booked_on" class="button button--primary" @click="unbook">
          <i class="fa-regular fa-calendar-circle-minus"></i>&nbsp;{{ __("conference_sessions.row.buttons.remove_from_schedule") }}
        </button>
        <button v-else-if="!conference.policy.is_busy && conference.policy.can_book" class="button button--primary" @click="book">
          <i class="fa-regular fa-calendar-circle-plus"></i>&nbsp;{{ __("conference_sessions.row.buttons.add_to_schedule") }}
        </button>
        <button v-else-if="conference.policy.is_busy" class="button button--primary button--disabled">
          {{ __("conference_sessions.row.buttons.schedule_clash") }}
        </button>
      </div>
    </template>
  </BaseModal>
</template>

<script>
import axios from 'axios';
import { store } from '../../../Frontend/store';
import ModalExpandable from '../ModalExpandable.vue';

export default {
  props: {
    sessionId: {
      type: String,
      required: true,
    },
    color: {
      type: String,
      default: 'palette-1',
    },
  },
  data() {
    return {
      conference: {
        type: Object,
      },
      loading: true,
      errored: false,
    };
  },
  components: { ModalExpandable },
  mounted() {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    };
    axios.get(this.endpoint, config)
      .then((response) => {
        this.conference = response.data.data;
      }).then(() => {
        this.loading = false;
      }).catch(() => {
        this.errored = true;
      });
  },
  methods: {
    book() {
      this.loading = true;
      axios
        .post(`${this.conferenceUrl}/book`)
        .then((response) => {
          this.conference = response.data.session;
          this.emitter.emit('conference_session.booking', {
            policies: response.data.policies,
          });
          this.loading = false;
        })
        .catch(() => {
          // eslint-disable-next-line no-alert
          alert(
            'Something went wrong, it is likely you have a schedule clash.\nPlease refresh your browser and try again.',
          );
        });
    },
    unbook() {
      this.loading = true;
      axios
        .post(`${this.conferenceUrl}/unbook`)
        .then((response) => {
          this.conference = response.data.session;
          this.emitter.emit('conference_session.booking', {
            policies: response.data.policies,
          });
          this.loading = false;
        });
    },
    modalClose() {
      this.$refs.modal.close();
    },
  },
  computed: {
    eventEdition() {
      return store.eventEdition;
    },
    isIframed() {
      return window.location.pathname.includes('iframe/conference-directories/');
    },
    endpoint() {
      if (this.isIframed) {
        return `${window.location.pathname}/sessions/${this.sessionId}/preview`;
      }
      return `/${this.eventEdition.slug}/conference-sessions/${this.sessionId}`;
    },
    conferenceUrl() {
      return `/${this.eventEdition?.slug}/conference-sessions/${this.conference.id}`;
    },
  },
};
</script>
