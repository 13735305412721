<template>
  <BaseModal
    ref="modal"
    width="wide"
    height="tall"
    :loading="loading"
    startOpen
  >
    <template #subheader>
      <div class="update-availability">
        <h1 v-text="__('update_availability.header')"></h1>
        <p
          class="update-availability__subheader"
          v-html="__('update_availability.subheader')"
          >
        </p>
        <p class="update-availability__key">
          <span
            class="update-availability__key-item update-availability__key-item--available"
            v-text="__('update_availability.key.available')"
            >
          </span>
          <span
            class="update-availability__key-item update-availability__key-item--unavailable"
            v-text="__('update_availability.key.unavailable')"
            >
          </span>
        </p>
      </div>
    </template>
    <form class="update-availability" @submit.prevent="update" ref="availabilityForm">
      <div class="update-availability__day" v-for="day in days" :key="day.date">
        <div class="update-availability__date">
          <a href="#" @click.prevent="toggleSlots(day.slots)" class="text-black">
            <i class="far fa-calendar-day"></i> {{ day.day }}
          </a>
          <small v-if="enableTimezones">(<a :href="updateTimezoneUrl">{{ timezone }}</a>)</small>
        </div>
        <ul class="update-availability__slots">
          <li
            v-for="slot in day.slots"
            :key="slot.datetime_utc"
            class="update-availability__slot"
            :class="{
              'update-availability__slot--available': !slot.busy && slot.available,
              'update-availability__slot--busy': slot.busy,
            }"
            @click.stop="toggleSlot(slot)"
            >
            {{ slot.time }}
          </li>
        </ul>
      </div>
      <div
        class="update-availability__error"
        v-if="error"
        v-text="error"
      >
      </div>
    </form>
    <template #footer>
      <div class="button-group">
        <button
          type="button"
          class="update-availability__save button"
          @click="update"
        >
          {{ __('update_availability.buttons.update') }}
        </button>
        <button
          type="button"
          class="button button--tertiary"
          @click="modalClose"
        >
          {{ __('update_availability.buttons.cancel') }}
        </button>
      </div>
    </template>
  </BaseModal>
</template>

<script>
import axios from 'axios';
import { store } from '../../../Frontend/store';

export default {
  data() {
    return {
      days: [],
      error: null,
      loading: false,
      timezone: '',
      updateTimezoneUrl: '',
      enableTimezones: false,
    };
  },
  mounted() {
    this.loading = true;
    this.error = null;
    axios.get(this.endpoint).then(
      (response) => {
        this.days = response.data.availability_days;
        this.timezone = response.data.timezone;
        this.updateTimezoneUrl = response.data.update_timezone_url;
        this.enable_timezones = response.data.enable_timezones;
      },
      // eslint-disable-next-line no-underscore-dangle
    ).catch(() => { this.error = this.__('general.unknown_error'); })
      .then(() => { this.loading = false; });
  },
  methods: {
    toggleSlots(slots) {
      slots.forEach((slot) => this.toggleSlot(slot));
    },
    toggleSlot(slot) {
      if (slot.busy) {
        return;
      }
      slot.available = !slot.available;
    },
    update() {
      this.error = null;
      this.loading = true;
      const slots = [].concat(...this.days.map((day) => day.slots));
      axios.patch(this.endpoint, { availability_slots: slots })
        .then(() => {
          this.modalClose();
          window.location.reload();
        })
        // eslint-disable-next-line no-underscore-dangle
        .catch(() => { this.error = this.__('general.unknown_error'); })
        .then(() => { this.loading = false; });
    },
    modalOpen() {
      this.$refs.modal.open();
    },
    modalClose() {
      this.$refs.modal.close();
    },
  },
  computed: {
    endpoint() {
      return `/${this.eventEdition?.slug}/schedule/availability`;
    },
    eventEdition() {
      return store.eventEdition;
    },
  },
};

</script>
