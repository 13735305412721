<template>
  <ModalForm
    :form="{
      action,
      method,
      name: 'catalogue-entry',
    }"
    :modal="{
      title: __(`profile.forms.catalogueEntry.${formType}.modalTitle`, {
        name: type.name,
        interpolation: { escapeValue: false },
      }),
      titleIcon: 'far fa-edit',
      height: 'tall',
    }"
    ref="modal"
    @success="bubbleSuccess"
  >
    <FormText
      name="name"
      :default="isUpdate ? entry.name : ''"
      :label="__('catalogue.catalogueEntry.name')"
      :max="255"
      required
      />

    <FormImage
      name="image"
      :default="isUpdate && entry.main_image ? entry.main_image.thumbnail.url : ''"
      :label="__('catalogue.catalogueEntry.image')"
      required
      />

    <FormTextarea
      name="description"
      :modelValue="isUpdate ? entry.description : ''"
      :label="__('catalogue.catalogueEntry.description')"
      :max="1000"
      required
      />

    <FormTreeSelect
      v-for="demographic in visibleDemographics"
      :key="demographic.id"
      :name="`demographic_values[${demographic.id}]`"
      :label="demographic.name"
      :options="optionsMap.get(demographic.id)"
      :multiple="demographic.is_multi"
      :selected="selectedMap.get(demographic.id)"
      :valueLimit="demographic.value_limit"
      @change="(newValues) => updateSelectedDemographic(demographic.id, newValues)"
      />

    <FeDivider>
      {{ __('catalogue.catalogueEntry.moreOptions') }}
    </FeDivider>

    <FormLinks
      name="links"
      :label="__('catalogue.catalogueEntry.links')"
      :value="entry ? entry.links : null"
      />

    <FormMulti
      v-if="type.enable_keywords"
      name="keywords"
      :label="__('catalogue.catalogueEntry.keywords')"
      :value="entry ? entry.keywords.map((keyword) => keyword.name) : null"
      />

    <FormMulti
      v-if="type.enable_highlights"
      name="highlights"
      :label="__('catalogue.catalogueEntry.highlights')"
      :value="entry ? entry.highlights.map((keyword) => keyword.name) : null"
      />

    <FormHidden name="type" :value="type.id"  />

    <FormSelect
      :label="__('profile_select.primary_contact')"
      :default="defaultCreator"
      name="primary_contact"
      :options="colleagues"
    />
    <template #footer>
      <div class="button-group">
        <button type="button" class="button button--secondary" @click="close">
          {{ __('buttons.cancel') }}
        </button>
        <FormSubmit>
          {{ __('buttons.save') }}
        </FormSubmit>
      </div>
    </template>
  </ModalForm>
</template>

<script>

import { user } from '../../../../Frontend/store';
/*
 * @argument Object value DemographicValue
 *
 * @return TreeOption
 */
const toTreeOption = (value) => ({
  value: value.id,
  label: value.name,
  options: value.descendants?.map(toTreeOption),
});

export default {
  props: {
    entry: {
      type: Object,
      required: false,
    },
    demographics: {
      type: Array,
      required: true,
    },
    type: {
      type: Object,
      required: true,
    },
    colleagues: {
      type: Array,
      required: false,
    },
  },
  emits: ['success'],
  expose: ['open', 'close'],
  data() {
    const data = {
      selectedDemographicValues: new Map(this.demographics.map((demographic) => [
        demographic.id,
        this.entry
          ? this.entry.demographic_values
            .filter((value) => value.demographic_id === demographic.id)
            .map((value) => value.id)
          : [],
      ])),
    };

    return data;
  },
  computed: {
    formType() {
      return this.isCreate ? 'create' : 'edit';
    },
    isCreate() {
      return !this.entry;
    },
    isUpdate() {
      return !!this.entry;
    },
    method() {
      return this.isCreate ? 'post' : 'patch';
    },
    action() {
      const url = '/profile/catalogue-entries';
      return this.isCreate ? url : `${url}/${this.entry.id}`;
    },
    defaultCreator() {
      return this.entry?.creator_id ? `${this.entry?.creator_id}` : `${user.activeContact.id}`;
    },
    optionsMap() {
      return new Map(this.demographics.map((demographic) => [
        demographic.id,
        demographic.values.map(toTreeOption),
      ]));
    },
    selectedMap() {
      return new Map(this.demographics.map((demographic) => [
        demographic.id,
        this.entry
          ? this.entry.demographic_values
            .filter((value) => value.demographic_id === demographic.id)
            .map((value) => value.id)
          : [],
      ]));
    },
    visibleDemographics() {
      return this.demographics.filter(
        (demographic) => demographic.dependent_on.length === 0
          || demographic.dependent_on.some(
            (demog) => this.selectedDemographicValues.get(demog.demographic_id)?.includes(demog.id),
          ),
      );
    },
  },
  methods: {
    open() {
      this.$refs.modal.open();
    },
    close() {
      this.$refs.modal.close();
    },
    bubbleSuccess(result) {
      this.$emit('success', result);
    },
    updateSelectedDemographic(demographicId, newValues) {
      this.selectedDemographicValues.set(demographicId, newValues);
    },
  },
};
</script>
