<template>
  <div
    class="profile-table__row profile-table__row--with-status-border"
    :class="[
      {'profile-table__row-highlight': task.status === 'complete'},
      {'profile-table__row--warning profile-table__row--disabled': task.status !== 'complete' && (outOfStock || task.deadline_is_past)},
    ]"
    >
    <div class="profile-table__row-icon--small">
      <i :class="icon"></i>
    </div>
    <div class="profile-table__row-text">
      <div class="profile-table__row-text--lead">
        {{ task.title }}
      </div>
      <div class="profile-table__row-text--light">
        <text-clamp
          :text="task.description"
          :max-lines="2"
          ellipsis="…"
          auto-resize
        />
      </div>
      <div class="chip-list">
        <template v-if="task.capacity !== null && task.status !== 'complete'">
          <span v-if="task.capacity_remaining > 0" class="chip chip--small chip--success">
           {{ __('hubs.tasks.capacity_remaining', {'capacity_remaining' : task.capacity_remaining }) }}
          </span>
          <span v-else-if="task.capacity_remaining <= 0" class="chip chip--small chip--warning">
           {{ __('hubs.tasks.capacity_remaining_zero') }}
         </span>
        </template>
        <span
          class="chip chip--small"
          :class="task.task_type === 'mandatory' ? 'chip--alert' : 'chip--neutral'"
        >
          <i v-if="task.task_type !== 'optional'" class="far fa-exclamation-circle"></i>
          {{ __(`hubs.tasks.taskType.${task.task_type}`) }}
        </span>
        <span
          v-if="task.status === 'complete'"
          class="chip chip--small chip--success'"
        >
          <i class="far fa-check-circle"></i> {{ task.response.responded_at_diff }}
        </span>
        <span
          v-else
          class="chip chip--small"
          :class="task.deadline_is_past ? 'chip--danger' : 'chip--alert'"
        >
          <i
          class="far fa-clock"
          ></i> {{ task.deadline_is_past ? __('hubs.tasks.expired') : task.deadline_diff}}
        </span>
      </div>
    </div>

    <button
      v-if="task.status === 'complete'"
      type="button"
      class="button button--secondary"
      @click="openModal(task)"
    >
      {{ __('hubs.tasks.view') }}
    </button>
    <button
      v-else-if="task.deadline_is_past"
      type="button"
      class="button button--secondary"
      @click="openModal(task)"
      disabled
    >
      {{ __('hubs.tasks.view') }}
    </button>
    <button
      v-else-if="outOfStock"
      type="button"
      class="button button--secondary"
      @click="openModal(task)"
      disabled
    >
      {{ __('hubs.tasks.capacity_remaining_zero') }}
    </button>
    <button
      v-else
      type="button"
      class="button button--secondary"
      @click="openModal(task)"
    >
      {{ __('hubs.tasks.start') }}
    </button>
  </div>

  <HubTaskModal
    v-if="taskInput?.id"
    :task='taskInput'
    :icon="icon"
    :outOfStock="outOfStock"
    @responded="(tasks) => $emit('responded', tasks)"
    @closed="modalClosed"
  ></HubTaskModal>

</template>

<script>
import _ from 'lodash';
import TextClamp from 'vue3-text-clamp';
import HubTaskModal from '~/Components/Frontend/Hubs/Modals/HubTaskModal.vue';

export default {
  components: {
    HubTaskModal,
    TextClamp,
  },
  props: {
    task: {
      type: Object,
      required: true,
    },
  },
  emits: ['responded'],
  data() {
    return {
      taskInput: {},
    };
  },
  computed: {
    icon() {
      if (this.task.response_type === 'info-only') {
        return 'far fa-info-circle';
      }
      return 'far fa-list-check';
    },
    outOfStock() {
      return this.task.capacity !== null && this.task.capacity_remaining <= 0;
    },
  },
  methods: {
    openModal(task) {
      this.taskInput = _.cloneDeep(task);
    },
    modalClosed() {
      this.taskInput = {};
    },
  },
};

</script>
