<template>
  <div class="modal__header profile2-header">

    <ContactAvatar :contact="activeContact" size="small" />
    <div class="profile2-header__contact-holder">
      <div class="profile2-header__contact-name"> {{ fullName }} </div>
      <div class="profile2-header__contact-info"> {{ jobTitle }}, {{ companyName }} </div>
    </div>

    <Popper
      v-if="!tabletOrSmaller"
      :content="__('ui.language')"
      hover
      arrow
    >
      <LocaleSwitcher
        :locales="locales"
        buttonClasses="profile2-header__link"
        icon="fa-xl fa-regular fa-globe"
      />
    </Popper>

    <Popper
      v-if="!tabletOrSmaller"
      :content="__('ui.support')"
      hover
      arrow
    >
      <a href="#/concierge-contact" class="profile2-header__link profile2-header__help-button">
        <i class="fa-xl fa-solid fa-user-headset"></i>
      </a>
    </Popper>

    <Popper
      v-if="!tabletOrSmaller && activeContact.has_multiple_profiles"
      :content="__('ui.switchProfile')"
      hover
      arrow
    >
      <a
        href="#/profile-select"
        class="profile2-header__link"
      >
        <i class="fa-xl far fa-user-group"></i>
        <i class="fas fa-repeat"></i>
      </a>
    </Popper>

    <button @click="$emit('closed')" class="button button--black profile2-header__close-button">
      {{ __('buttons.close')}} <i class='fa fa-xmark'></i>
    </button>
    <a @click="$emit('closed')" class="profile2-header__close-button-x">
      <i class="fa-fw fa-duotone fa-circle-x"></i>
    </a>
  </div>

</template>
<script>

import Popper from 'vue3-popper';
import { user } from '~/Frontend/store';
import { useBreakpoints } from '~/Frontend/useBreakpoints';
import ContactAvatar from '~/Components/Frontend/CRM/Components/ContactAvatar.vue';
import LocaleSwitcher from '~/Components/Frontend/LocaleSwitcher.vue';

export default {
  name: 'ProfileModalHeader',
  components: {
    ContactAvatar,
    LocaleSwitcher,
    Popper,
  },
  props: {
    locales: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      activeLocale: document.head.querySelector('meta[name="locale-code"]').content,
    };
  },
  computed: {
    activeContact: () => user.activeContact,
    jobTitle: () => user.activeContact.job_title,
    companyName: () => user.activeContact.company.legal_name,
    fullName: () => user.activeContact.full_name,
  },
  setup() {
    const { tabletOrSmaller } = useBreakpoints();
    return { tabletOrSmaller };
  },
};
</script>
