import axios from 'axios';
import { store } from '../store';

export default function () {
  return {
    show(meeting) {
      return axios.get(`/${store.eventEdition.slug}/meetings/${meeting}`);
    },
    cancel(meeting, cancellationReason, cancellationMessage) {
      return axios.post(`/${store.eventEdition.slug}/meetings/${meeting}/cancel`, {
        cancellation_reason: cancellationReason,
        cancellation_message: cancellationMessage,
      });
    },
  };
}
