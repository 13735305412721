<template>
  <BaseModal
    ref="modal"
    width="wide"
    height="tall"
    :loading="loading"
    startOpen
  >
    <div class="login__legal-page markdown" v-if="legalPage" v-html="legalPage.body_html"/>
  </BaseModal>
</template>

<script>
import axios from 'axios';

export default {
  name: 'LegalPage',
  props: {
    slug: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      legalPage: {},
    };
  },
  mounted() {
    this.loading = true;
    axios.get(`legal/legal-pages/${this.slug}`)
      .then((response) => {
        this.legalPage = response.data.legal_page;
      }).catch((response) => {
        this.errors = response.data?.errors ?? {};
      }).then(() => {
        this.loading = false;
      });
  },
};
</script>
