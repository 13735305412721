<template>
  <NotificationBar v-if="hasMeetingsToRate" type="error">
    <span v-html="__('inbox.meetings.meetingsToRate', {
      count: meetingsToRate.length,
      nextId: meetingsToRate[0].id,
    })"></span>
  </NotificationBar>
  <FeLoading v-if="loading" />
  <template v-else>
    <div v-if="days.length > 0" class="filter-row no-scroll-bars">
      <FilterSelect
        v-if="smallLaptopOrSmaller"
        v-model="selectedDay"
        name="day"
        icon="far fa-calendar-day"
      >
        <FilterSelectOption
          v-for="day in days"
          :key="day"
          :label="day === 'all' ? this.__('inbox.meetings.filters.allDays') : day"
          :value="day"
          :badgeNumber="counts[day]"
          :disabled="counts[day] === 0"
        />
      </FilterSelect>
      <FilterRadioGroup
        v-else
        v-model="selectedDay"
      >
        <FilterRadioOption
          v-for="day in days"
          :key="day"
          v-model="selectedDay"
          name="day"
          :label="day === 'all' ? this.__('inbox.meetings.filters.allDays') : day"
          :value="day"
          :badgeNumber="counts[day]"
          badgeType="positive"
          :disabled="counts[day] === 0"
        />
      </FilterRadioGroup>
    </div>
    <div v-if="meetings" class="inbox__results">
      <InboxMeeting
        v-for="meeting in meetingsFiltered"
        :key="meeting.id"
        :meeting="meeting"
        :activeContact="activeContact"
      />
      <div v-if="meetings.length === 0" class="inbox__no-results">
        <h1>
          <i class="fa-light fa-circle-check"></i> {{ __('inbox.meetings.noResults.title') }}
        </h1>
        <p v-html="
          __('inbox.meetings.noResults.message', {
            findPeopleUrl: `/${defaultSearchPage}`
          })"></p>
        <a class="button button--secondary" :href="`/${defaultSearchPage}`">
          {{ __('inbox.invites.findPeople') }}
        </a>
      </div>
    </div>
  </template>
</template>

<script>
import _ from 'lodash';
import axios from 'axios';
import { useBreakpoints } from '~/Frontend/useBreakpoints';
import FilterRadioGroup from '~/Components/Frontend/Core/Filters/FilterRadioGroup.vue';
import FilterRadioOption from '~/Components/Frontend/Core/Filters/FilterRadioOption.vue';
import FilterSelect from '~/Components/Frontend/Core/Filters/FilterSelect.vue';
import FilterSelectOption from '~/Components/Frontend/Core/Filters/FilterSelectOption.vue';
import FeLoading from '~/Components/Frontend/Core/Misc/FeLoading.vue';
import InboxMeeting from '~/Components/Frontend/Meetings/Inbox/InboxMeeting.vue';
import { actionRequiredCounts, store, user } from '~/Frontend/store';
import NotificationBar from '~/Components/Frontend/NotificationBar.vue';

const listeners = [
  'invites.accepted',
  'meetings.cancelled',
  'meetings.rescheduled',
  'meetings.rated',
];

export default {
  name: 'MeetingsPage',
  components: {
    FeLoading,
    FilterRadioGroup,
    FilterRadioOption,
    FilterSelect,
    FilterSelectOption,
    InboxMeeting,
    NotificationBar,
  },
  props: {
    defaultSearchPage: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      meetings: [],
      loading: false,
      sort: null,
      selectedDay: 'all',
    };
  },
  computed: {
    days() {
      return [
        'all',
        ..._.uniq(this.meetings.map((meeting) => meeting.date_medium)),
      ];
    },
    counts() {
      const counts = {};
      this.days.forEach((day) => {
        if (day === 'all') {
          counts.all = this.meetings.filter((meeting) => !meeting.cancelled_at).length;
        } else {
          counts[day] = this.meetings.filter((meeting) => (
            meeting.date_medium === day && !meeting.cancelled_at
          )).length;
        }
      });
      return counts;
    },
    meetingsFiltered() {
      if (this.selectedDay === 'all') {
        return this.meetings;
      }
      return this.meetings.filter((meeting) => meeting.date_medium === this.selectedDay);
    },
    activeContact() {
      return user.activeContact;
    },
    eventEdition() {
      return store.eventEdition;
    },
    storeActionRequiredCounts() {
      return actionRequiredCounts;
    },
    hasMeetingsToRate() {
      return this.meetingsToRate.length > 0;
    },
    meetingsToRate() {
      return this.meetings.filter((meeting) => !meeting.cancelled_at
        && meeting.can_rate
        && meeting.has_finished);
    },
  },
  methods: {
    loadData() {
      if (this.loading) {
        return;
      }
      this.loading = true;
      const url = new URL(window.location);
      const params = new URLSearchParams({
        query: 'meetings',
        sort: this.sort,
      });
      const ajaxUrl = `${url.pathname}?${params.toString()}`;
      axios.get(`${ajaxUrl}&ajax=true`).then((response) => {
        this.meetings = response.data.meetings;
      }).then(() => {
        this.loading = false;
      });
    },
  },
  mounted() {
    this.emitter.on('*', (type) => {
      if (listeners.includes(type)) {
        this.loadData();
      }
    });
    this.loadData();
  },
  setup() {
    const { smallLaptopOrSmaller, mobile } = useBreakpoints();
    return { smallLaptopOrSmaller, mobile };
  },
};

</script>
