<template>
  <BaseModal
    :title="video.title"
    height="tall"
    startOpen
  >
    <template v-if="video?.id">
      <div class="base-modal__container--wide" style="margin-bottom: 1rem;">
        <VideoPlayer :videoAsset="video" style="width:100%"></VideoPlayer>
      </div>

      <h2 class="video-asset-preview__title">
        {{ video.title }}
      </h2>

      <template v-if="video.description">
        <p>
          {{ video.description }}
        </p>
      </template>
      <hr>

      <template
        v-for="demographic in video.demographics"
        :key="demographic.id"
        >
        <h5 class="video-asset-preview__demographic">{{ demographic.name }}</h5>
        <ul class="video-asset-preview__demographic-values">
          <li
            v-for="value in demographic.values"
            :key="value.id"
            class="video-asset-preview__demographic-value"
            >
            {{ value.full_name }}
          </li>
        </ul>
      </template>

      <div class="divider" style="padding:1rem 0;">
        <h5 v-if="company.display_name">{{ company.display_name }}</h5>
        <p v-if="company.tagline !== '.'"> {{ company.tagline }} </p>
      </div>
    </template>
    <template #footer v-if="company">
      <div class="button-group">
        <button
          v-if="video?.id"
          class="button"
          :target="isIframed ? '_blank' : '_self'"
          @click.prevent="goToCompany(company.id)"
        >
          {{ __(`videoModal.buttons.view_company`) }}
        </button>
      </div>
    </template>
  </BaseModal>
</template>

<script>
import axios from 'axios';
import { store } from '../../../Frontend/store';
import VideoPlayer from '../VideoPlayer.vue';

export default {
  props: {
    videoId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      errored: false,
      video: {},
      company: {},
    };
  },
  components: {
    VideoPlayer,
  },
  mounted() {
    this.loadData();
  },
  methods: {
    goToCompany() {
      window.location.href = `/${this.eventEdition.slug}/companies/${this.company.id}`;
    },
    loadData() {
      axios
        .get(this.previewUrl)
        .then((response) => {
          this.video = response.data.video;
          this.company = response.data.company;
        })
        .catch(() => {
          this.errored = true;
        });
    },
  },
  computed: {
    eventEdition() {
      return store.eventEdition;
    },
    isIframed() {
      return window.location.pathname.includes('iframe/video-directories');
    },
    previewUrl() {
      if (this.isIframed) {
        return `${window.location.pathname}/video/${this.videoId}/preview`;
      }
      return `/${this.eventEdition?.slug}/video/${this.videoId}/preview`;
    },
  },
};
</script>
