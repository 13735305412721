<template>
  <div class="profile-table">
    <div class="profile-table__header">
      <div class="profile-table__header-label">
        <h3>
          {{ __('profile.tables.imageGallery.title') }}
          <small>{{ __('profile.tables.imageGallery.count', { count: list.length }) }}</small>
        </h3>
      </div>
      <div class="profile-table__header-action">
        <button class="button button--link" @click.prevent="$refs.createModal.open()" >
          <i class="far fa-plus-circle"></i> {{ __('buttons.add') }}
        </button>
      </div>
    </div>

    <div v-if="list.length == 0" class="profile-table-row">
      {{ __('profile.tables.imageGallery.emptyMessage') }}
    </div>

    <draggable
      tag="div"
      :component-data="{
        tag: 'div',
        type: 'transition-group',
        name: !drag ? 'flip-list' : null,
      }"
      v-model="list"
      :animation="200"
      @start="handleStart"
      @end="drag = false"
      @sort="handleSort"
      item-key="id"
      class="profile-table__body list-group"
      ghost-class="ghost"
      handle=".handle"
      v-if="list.length > 0"
      >
      <template #item="{  element: image }">
        <ImageGalleryTableRow
          :endpoint="`/company-profile/profile-images/${image.id}`"
          :image="image"
          @updated="(newImage) => { patchUpdate(newImage) }"
          @deleted="this.list = this.list.filter((i) => i.id !== image.id)"
          />
      </template>
    </draggable>
  </div>

  <ImageForm
    @success="handleCreate"
    ref="createModal"
    action="/company-profile/profile-images"
  />

</template>

<script>
import draggable from 'vuedraggable';
import _ from 'lodash';
import axios from 'axios';

export default {
  components: {
    draggable,
  },
  props: {
    gallery: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      list: this.gallery,
      drag: false,
    };
  },
  methods: {
    patchUpdate(image) {
      const ndx = this.list.findIndex((i) => i.id === image.id);
      this.list[ndx] = image;
    },
    handleStart(start) {
      const img = new Image();
      img.src = 'https://www.litmus.com/wp-content/uploads/2021/02/ease-applied-to-tween-with-bouncein-example.gif';
      start.originalEvent.dataTransfer.setDragImage(img, 10, 10);
      this.drag = true;
    },
    handleSort() {
      axios.patch('/company-profile/profile-images/reorder', {
        keys: _.map(this.list, 'id'),
      }).catch((response) => { this.errors = response?.data?.errors; });
    },
    handleCreate(result) {
      // A little hack to trigger a change
      result.image.fresh = true;
      this.list.push(result.image);
    },
  },
};

</script>

<style>
.ghost {
  opacity: 1;
  background: #c8ebfb;
}
</style>
