<template>
  <PageHeader
    :showBack="(!navRevealed) && tabletOrSmaller"
    :showHelp="smallLaptopOrSmaller && (!navRevealed)"
    @back="navRevealed = true"
    @help="$refs.helpModal.open()"
  >
    <template #header>
        <div class="page-header__heading">
            <h1>
              <i class="far" :class="hub.icon"></i>
              {{ hub.title }}
            </h1>
          <div class="page-header__subheading" v-if="hub.subtitle" v-html="hub.subtitle"></div>
          <h3>{{ company.display_name }} {{ company.stand ? '('+ __('hubs.header.stand', {stand: company.stand}) + ')' : '' }} <span class="chip" v-if="company.badge">{{ company.badge }}</span></h3>
        </div>
    </template>
  </PageHeader>
  <Layout3Column :activeColumn="activeColumn">
    <template #left>
      <ul class="navigation-panel">
        <NavigationItem
          v-if="hub.enable_custom_page"
          :label="hub.custom_page_title"
          icon="far fa-info-circle"
          :activeTab="activeTab"
          code="custom_page"
          name="custom_page"
          @tabClick="setPage('custom_page')"
          />
        <NavigationItem
          v-if="hub.enable_insights"
          :label="__('hubs.insights.link')"
          icon="far fa-gauge"
          :activeTab="activeTab"
          code="insights"
          name="insights"
          @tabClick="setPage('insights')"
          />
        <NavigationItem
          v-if="hub.enable_tasks"
          :label="__('hubs.tasks.link')"
          icon="far fa-list-check"
          :activeTab="activeTab"
          code="tasks"
          name="tasks"
          @tabClick="setPage('tasks')"
          />
        <NavigationItem
          v-if="hub.enable_event_profile"
          :label="__('hubs.eventProfile.link')"
          icon="far fa-cog"
          :activeTab="activeTab"
          code="event_profile"
          name="event_profile"
          @tabClick="setPage('event_profile')"
          />
        <NavigationItem
          v-if="hub.enable_team"
          :label="__('hubs.team.link')"
          icon="fas fa-people-group"
          code="team"
          name="team"
          :activeTab="activeTab"
          @tabClick="setPage('team')"
          />
        <NavigationItem
          v-if="hub.enable_meetings"
          :label="__('hubs.meetings.link')"
          icon="far fa-handshake"
          code="meetings"
          name="meetings"
          :activeTab="activeTab"
          @tabClick="setPage('meetings')"
          />
        <NavigationItem
          v-if="hub.enable_leads"
          :label="__('hubs.leads.link')"
          icon="fas fa-chart-mixed-up-circle-dollar"
          code="leads"
          name="leads"
          :activeTab="activeTab"
          @tabClick="setPage('leads')"
          />
        <NavigationItem
          v-if="hub.enable_purchase_items"
          :label="__('hubs.purchase_items.link')"
          icon="far fa-basket-shopping"
          code="purchase_items"
          name="purchase_items"
          :activeTab="activeTab"
          @tabClick="setPage('purchase_items')"
          />
      </ul>
    </template>
    <component :is="page" v-bind="pageProps"></component>
    <template #right>
      <HelpPanel
        v-if="activeTab"
        :helpVideo="helpPanes[activeTab].helpVideo"
        faqHeader="FAQs"
        :faqs="helpPanes[activeTab].faqs"
        :dataPrivacy="activeTab === 'insights' || activeTab === 'leads'"
      />
    </template>
    <teleport to="body">
      <BaseModal ref="helpModal" height="tall">
        <HelpPanel
        v-if="activeTab"
        :helpVideo="helpPanes[activeTab].helpVideo"
        faqHeader="FAQs"
        :faqs="helpPanes[activeTab].faqs"
        :dataPrivacy="activeTab === 'insights' || activeTab === 'leads'"
        borderless
      />
      </BaseModal>
    </teleport>
  </Layout3Column>
</template>

<script>

import { useBreakpoints } from '~/Frontend/useBreakpoints';
import { user, store } from '~/Frontend/store';
import HelpPanel from '~/Components/Frontend/Core/Panels/HelpPanel.vue';
import EventProfilePage from './Pages/EventProfilePage.vue';
import EventTeamPage from './Pages/EventTeamPage.vue';
import HubTasksPage from './Pages/HubTasksPage.vue';
import HubCustomPage from './Pages/HubCustomPage.vue';
import HubLeadsPage from './Pages/HubLeadsPage.vue';
import HubMeetingsPage from './Pages/HubMeetingsPage.vue';
import HubPurchaseItemPage from './Pages/HubPurchaseItemPage.vue';
import InsightsPage from './Pages/InsightsPage.vue';

export default {
  name: 'EventHub',
  props: {
    /**
     * help-panes, Object as it's keyed.
     * insights: {
     *   helpVideo: 'html to render the video',
     *   faqs: [
     *     {
     *       label: 'string',
     *       answer: 'html string',
     *     },
     *   ],
     * }
     */
    hub: {
      type: Object,
      required: true,
    },
    helpPanes: {
      type: Object,
      required: true,
    },
    company: {
      type: Object,
      required: false,
    },
  },
  components: {
    EventProfilePage,
    EventTeamPage,
    HubTasksPage,
    HelpPanel,
    HubCustomPage,
    HubLeadsPage,
    HubMeetingsPage,
    HubPurchaseItemPage,
    InsightsPage,
  },
  data() {
    return {
      activeTab: this.initialTab(),
      navRevealed: this.tabletOrSmaller,
    };
  },
  computed: {
    activeColumn() {
      if (this.navRevealed) {
        return 1;
      }
      return 2;
    },
    page() {
      return (this.activeTab)
        ? {
          insights: InsightsPage,
          tasks: HubTasksPage,
          event_profile: EventProfilePage,
          team: EventTeamPage,
          meetings: HubMeetingsPage,
          leads: HubLeadsPage,
          purchase_items: HubPurchaseItemPage,
          custom_page: HubCustomPage,
        }[this.activeTab]
        : null;
    },
    pageProps() {
      if (!this.activeTab) {
        return {};
      }
      if (this.activeTab === 'custom_page') {
        return {
          hub: this.hub,
        };
      }
      return {};
    },
    eventEdition() {
      return store.eventEdition;
    },
    activeContact() {
      return user.activeContact;
    },
  },
  setup() {
    const { smallLaptopOrSmaller, mobile, tabletOrSmaller } = useBreakpoints();
    return { smallLaptopOrSmaller, mobile, tabletOrSmaller };
  },
  methods: {
    setPage(name) {
      this.navRevealed = false;
      this.activeTab = name;
    },
    initialTab() {
      if (this.hub.enable_custom_page) {
        return 'custom_page';
      }
      if (this.hub.enable_insights) {
        return 'insights';
      }
      if (this.hub.enable_tasks) {
        return 'tasks';
      }
      if (this.hub.enable_event_profile) {
        return 'event_profile';
      }
      if (this.hub.enable_team) {
        return 'team';
      }
      if (this.hub.enable_meetings) {
        return 'meetings';
      }
      if (this.hub.enable_leads) {
        return 'leads';
      }
      if (this.hub.enable_purchase_items) {
        return 'purchase_items';
      }
      return null;
    },
  },
};
</script>
