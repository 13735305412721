<template>
  <div class="profile-row">
    <div class="profile-row__label">
      {{ __('crm.contact.avatar') }}
      <FeBadge v-if="!avatar" alert />
    </div>
    <div class="profile-row__value" v-if="avatar" >
      <FeImage
        class="profile-row__avatar"
        :image="avatar"
        format="avatar"
        placeholderIcon="fa-exclamation-triangle"
        :alt="__('crm.contact.avatar')"
      />
    </div>
    <div class="profile-row__value profile-row__value--invalid" v-else>
      {{ __('ui.notAdded') }}
    </div>
    <div class="profile-row__action">
      <button class="button button--link" @click="openModal">
        <i class="fa fa-edit"></i>
        {{ __('buttons.edit') }}
      </button>
    </div>
  </div>
  <ModalForm
    ref="modal"
    :form="{name: 'avatar', method: 'patch', action: '/profile/avatar'}"
    :modal="{
      title: __('profile.rows.avatarModalTitle'),
      titleIcon: 'far fa-image-polaroid-user',
    }"
    @success="update"
  >
    <FormImage
      name="avatar"
      :button="__('buttons.uploadImage')"
      :default="src"
      />
    <template #footer>
      <div class="button-group">
        <button type="button" class="button button--secondary" @click="closeModal">
          {{ __('buttons.cancel') }}
        </button>
        <FormSubmit> {{ __('buttons.save') }} </FormSubmit>
      </div>
    </template>
  </ModalForm>
</template>

<script>

import { user } from '~/Frontend/store';

export default {
  computed: {
    avatar: () => user.activeContact.avatar,
  },
  methods: {
    openModal() {
      this.$refs.modal.open();
    },
    closeModal() {
      this.$refs.modal.close();
    },
    update({ avatar }) {
      user.activeContact.avatar = avatar;
      this.closeModal();
    },
  },
};

</script>
