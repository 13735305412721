<template>
  <div class="profile-table__row" :class="{shake: recentlyUpdated}" v-bind="$attrs">
    <FeImage
      :class="[
        'profile-table__row-image',
        {
          'profile-table__row-image--small': small
        }
      ]"
      :image="image"
      format="card"
      placeholderIcon="fa-image"
      :alt="image.image_name || ''"
    />

    <div class="profile-table__row-name">
      <span>
        {{ name }}
      </span>
      <span
        v-if="tag"
        class="chip chip--small"
        style="margin-left: var(--sizing-2)"
        > {{ tag }}</span>
    </div>

    <EditIconButton @click="$refs.editModal.open()" />

    <DeleteIconButton @click="$refs.deleteModal.open()" />

    <i class="profile-table__row-grip far fa-grip-lines handle"></i>

    <Teleport to="body">
      <ImageForm
        :action="endpoint"
        :image="image"
        @success="updated"
        ref="editModal"
        @opened="$emit('modalOpened')"
        @closed="$emit('modalClosed')"
      />

      <BaseModal
        ref="deleteModal"
        :key="image.id"
        @opened="$emit('modalOpened')"
        @closed="$emit('modalClosed')"
        :title="__('ui.delete')"
        titleIcon="far fa-trash-can"
      >
        <p> {{ __('profile.tables.imageGallery.confirmDelete') }} </p>
        <p><strong>{{ image.image_name }}</strong></p>
        <div class="form-input">
          <div class="form-input__input form-input--image">
            <span class="form-input__label"> {{ image.image_name }} </span>
            <FeImage
              :image="image"
              format="card"
              className="form-input__preview"
              :alt="image.image_name || ''"
            />
          </div>
        </div>
        <template #footer>
          <div class="button-group">
            <button
              class="button button--secondary"
              @click="$refs.deleteModal.close()"
              >
              {{ __('buttons.cancel') }}
            </button>
            <button class="button button--warning" @click="destroy" :disabled="deleting">
              <div class="button__icon" v-if="deleting">
                <i class="fas fa-beat-fade fa-ellipsis"></i>
              </div>
              <template v-else>
                {{ __('buttons.delete') }}
              </template>
            </button>
          </div>
        </template>
      </BaseModal>
    </Teleport>
  </div>

</template>

<script>

import axios from 'axios';

export default {
  props: {
    image: {
      type: Object,
      required: true,
    },
    tag: {
      type: String,
      required: false,
    },
    endpoint: {
      type: String,
      required: true,
    },
    small: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['updated', 'deleted', 'modalOpened', 'modalClosed'],
  data() {
    return {
      recentlyUpdated: false,
      deleting: false,
    };
  },
  created() {
    this.recentlyUpdated = this.image.fresh;
  },
  computed: {
    name() {
      return this.image.image_name || 'Unnamed image';
    },
  },
  watch: {
    recentlyUpdated(newValue) {
      if (newValue) {
        window.setTimeout(() => { this.recentlyUpdated = false; }, 1000);
      }
    },
  },
  methods: {
    updated(result) {
      this.recentlyUpdated = true;
      this.$emit('updated', result.image);
    },
    destroy() {
      this.deleting = true;
      axios.delete(this.endpoint)
        .then(() => {
          this.deleting = false;
          this.$refs.deleteModal.close();
          this.$emit('deleted');
        });
    },
  },
};
</script>

<style>
.shake {
  animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
  transform: translate3d(0, 0, 0);
}

@keyframes shake {
  10%,
  90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%,
  80% {
    transform: translate3d(2px, 0, 0);
  }

  30%,
  50%,
  70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%,
  60% {
    transform: translate3d(4px, 0, 0);
  }
}
</style>
