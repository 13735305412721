<template>
  <div class="profile-table__row" :class="{shake: recentlyUpdated}">
    <VideoPlayer
      className="profile-table__row-image"
      :placeholderIcon="placeholderIcon"
      :videoAsset="video"
    />
    <div class="profile-table__row-name">
      {{ title }}:
      &nbsp;
      <span class="video-asset-entry__description margin-none">{{ description }}</span>
      <div class="chip-list">
        <span
          class="chip chip--small chip--neutral"
          v-if="!ready"
          :class="(failed || removed) ? 'chip--alert' : 'chip--neutral'"
        >
          {{ __(`mux_video_upload.video_status.${video.status}`) }}
        </span>
      </div>
    </div>

    <template v-if="canEdit">
      <EditIconButton @click="$refs.editModal.open()" />

      <DeleteIconButton @click="$refs.deleteModal.open()" />
    </template>

    <i class="profile-table__row-grip far fa-grip-lines handle"></i>

    <Teleport to="body">
      <VideoForm
         :video="video"
         :demographics="demographics"
         @success="updated"
         ref="editModal"
         @opened="$emit('modalOpened')"
         @closed="$emit('modalClosed')"
      />
      <BaseModal
        ref="deleteModal"
        :key="video.id"
        @opened="$emit('modalOpened')"
        @closed="$emit('modalClosed')"
        :title="__('ui.delete')"
        titleIcon="far fa-trash-can"
      >
        <p> {{ __('profile.tables.videos.confirmDelete') }}  </p>
        <p><strong>{{ video.title || __('assets.video.title.default') }}</strong></p>
        <div class="form-input">
          <div class="form-input__input form-input--image">
            <VideoPlayer :videoAsset="video" />
          </div>
        </div>
        <template #footer>
          <div class="button-group">
            <button
              class="button button--secondary"
              @click="$refs.deleteModal.close()"
              >
              {{ __('buttons.cancel') }}
            </button>
            <button
              class="button button--warning"
              @click="destroy()"
            >
              {{ __('buttons.delete') }}
            </button>
          </div>
        </template>
      </BaseModal>
    </Teleport>

  </div>

</template>

<script>

import axios from 'axios';

export default {
  props: {
    video: {
      type: Object,
      required: true,
    },
    demographics: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      recentlyUpdated: false,
    };
  },
  emits: ['updated', 'deleted', 'modalOpened', 'modalClosed'],
  created() {
    if (this.video.fresh) {
      this.recentlyUpdated = true;
    }
  },
  computed: {
    title() {
      return this.video.title || 'Untitled video';
    },
    description() {
      return this.video.description || 'No description';
    },
    ready() {
      return this.video.status === 'ready';
    },
    failed() {
      return this.video.status === 'upload-failed'
        || this.video.status === 'upload-cancelled'
        || this.video.status === 'errored';
    },
    removed() {
      return this.video.status === 'removed'
        || this.video.status === 'deleted';
    },
    canEdit() {
      return this.video.status === 'ready'
        || this.video.status === 'processing';
    },
    placeholderIcon() {
      if (this.failed) {
        return 'fa-ban';
      }
      if (this.removed) {
        return 'fa-trash-can';
      }
      return 'fa-timer';
    },
  },
  methods: {
    updated(videoAsset) {
      this.recentlyUpdated = true;
      this.$emit('updated', videoAsset);
      this.$refs.editModal.close();
    },
    destroy() {
      axios.delete(`/company-profile/video/${this.video.id}`)
        .then((response) => {
          this.$refs.deleteModal.close();
          this.$emit('deleted', response.data.video_asset);
        });
    },
  },
  watch: {
    video: {
      handler() {
        this.recentlyUpdated = true;
      },
      deep: true,
    },
    recentlyUpdated(newValue) {
      if (newValue) {
        window.setTimeout(() => { this.recentlyUpdated = false; }, 1000);
      }
    },
  },
};
</script>

<style>
.shake {
  animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
  transform: translate3d(0, 0, 0);
}

@keyframes shake {
  10%,
  90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%,
  80% {
    transform: translate3d(2px, 0, 0);
  }

  30%,
  50%,
  70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%,
  60% {
    transform: translate3d(4px, 0, 0);
  }
}
</style>
