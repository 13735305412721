<template>
  <ModalForm
    :form="{
      action,
      method,
      name: 'image-form',
    }"
    :modal="{
      title: __(`profile.forms.image.${formType}.modalTitle`),
      titleIcon: `${modalIcon}`,
      height: 'tall',
    }"
    @success="bubbleSuccess"
    ref="baseModal"
  >
    <FormText
      name="name"
      :default="isUpdate ? image.image_name : ''"
      :label="__('assets.image.name')"
      :max="255"
      required
      />

    <FormImage
      name="image"
      :default="isUpdate && image.card ? image.card.url : ''"
      :label="__('assets.image.image')"
      required
      />

    <template #footer>
      <div class="button-group">
        <button
          class="button button--secondary"
          type="button"
          @click="$refs.baseModal.close()"
          >
          {{ __('buttons.cancel') }}
        </button>
        <FormSubmit> {{ isUpdate ? __('buttons.update') : __('buttons.save') }} </FormSubmit>
      </div>
    </template>
  </ModalForm>
</template>

<script>

export default {
  expose: ['open'],
  props: {
    action: {
      type: String,
      required: true,
    },
    image: {
      type: Object,
      required: false,
    },
  },
  emits: ['success'],
  computed: {
    formType() {
      return this.isCreate ? 'create' : 'edit';
    },
    isCreate() {
      return !this.image;
    },
    isUpdate() {
      return !!this.image;
    },
    method() {
      return this.isCreate ? 'post' : 'patch';
    },
    modalIcon() {
      return this.isCreate ? 'far fa-plus-circle' : 'far fa-edit';
    },
  },
  methods: {
    bubbleSuccess(result) {
      this.$emit('success', result);
      this.$refs.baseModal.close();
    },
    open() {
      this.$refs.baseModal.open();
    },
  },
};
</script>
