export default {
  'assets.image.image': 'Image',
  'assets.image.name': 'Image title',
  'assets.video.title': 'Video title',
  'assets.video.description': 'Video description',
  'assets.video.title.default': 'Unamed video',
  'assets.video.video': 'Video',
  'buttons.add': 'Add',
  'buttons.addMore': 'Add more',
  'buttons.back': 'Back',
  'buttons.cancel': 'Cancel',
  'buttons.change': 'Change',
  'buttons.clear': 'Clear',
  'buttons.close': 'Close',
  'buttons.contact': 'Contact',
  'buttons.delete': 'Delete',
  'buttons.done': 'Done',
  'buttons.edit': 'Edit',
  'buttons.help': 'Help',
  'buttons.impersonateUser': 'Connect as',
  'buttons.manage': 'Manage',
  'buttons.manageImages': 'Manage images',
  'buttons.new': 'New',
  'buttons.next': 'Next',
  'buttons.return': 'Return',
  'buttons.save': 'Save',
  'buttons.sendLink': 'Send link',
  'buttons.set': 'Set',
  'buttons.set_password': 'Set password',
  'buttons.update': 'Update',
  'buttons.uploadImage': 'Upload image',
  'buttons.locked': 'Locked',
  'buttons.write_with_ai': 'Create with AI',
  'buttons.recreate_with_ai': 'Recreate with AI',
  'buttons.notifications': 'Notifications',
  'buttons.run_search': 'Run',
  'buttons.show_more': 'Show more',
  'buttons.show_less': 'Show less',
  'action.unfavourite': 'Unfavourite',
  'action.favourite': 'Favourite',
  'action.unhide': 'Unhide',
  'action.hide': 'Hide',
  'catalogue.catalogueEntry.description': 'Description',
  'catalogue.catalogueEntry.gallery': 'Images',
  'catalogue.catalogueEntry.highlights': 'Highlights',
  'catalogue.catalogueEntry.image': 'Image',
  'catalogue.catalogueEntry.keywords': 'Keywords',
  'catalogue.catalogueEntry.links': 'Links',
  'catalogue.catalogueEntry.moreOptions': 'More options',
  'catalogue.catalogueEntry.name': 'Title',
  'catalogue_product_form.add_image_success': 'You have successfully added an image to :filename, it will be visible on a page refresh.',
  'catalogue_product_form.delete': 'Delete',
  'catalogue_product_form.description': 'Description*:',
  'catalogue_product_form.edit': 'Edit',
  'catalogue_product_form.highlights.add': 'Add highlight',
  'catalogue_product_form.highlights.no_highlights': 'No Highlights Added',
  'catalogue_product_form.highlights.placeholder': '',
  'catalogue_product_form.highlights.title': 'Highlights',
  'catalogue_product_form.image': 'Product Image*',
  'catalogue_product_form.images': 'Images',
  'catalogue_product_form.keywords': 'Keywords',
  'catalogue_product_form.keywords_descriptions': "Separate multiple keywords with a comma ','",
  'catalogue_product_form.name': 'Name*:',
  'catalogue_product_form.order': 'Order:',
  'catalogue_product_form.order_description': 'Ordering is ascending (1 will be listed before 2).',
  'catalogue_product_form.recommended_image_size': 'Recommended 1280px by 720px (images will be displayed with a 16:9 aspect ratio). Maxiumum file size 5Mb',
  'catalogue_product_form.remove_image_success': 'You have successfully added an image to :filename, it will be visible on a page refresh.',
  'catalogue_product_form.save': 'Save',
  'catalogue_product_form.save_then_upload': 'Please save and then click edit to upload images.',
  'catalogue_product_form.subtitle': 'Subtitle*:',
  'catalogue_product_form.tagline': 'Tagline*:',
  'catalogue_product_form.title.create': 'Create {{ type }}',
  'catalogue_product_form.title.update': 'Edit {{ type }}',
  'catalogue_product_form.update': 'Save',
  'catalogue_product_form.update_photo': 'Edit photo',
  'catalogue_product_form.upload_photo': 'Upload photo',
  'catalogue_product_preview.buttons.view': 'View',
  'catalogue_product_preview.description': 'Description',
  'catalogue_product_preview.highlights': 'Highlights',
  'catalogue_product_preview.learn_more': 'Learn more',
  'catalogue_product_preview.more': 'More',
  'catalogue_product_preview.read_more': 'Read more',
  'catalogue_product_preview.show_less': 'Show less',
  'catalogue_product_preview.view_product': 'View product',
  'company.actions.business_card_dropped': 'Business card shared',
  'company.actions.drop_business_card': 'Share business card',
  'company.company_book_meeting_modal.cancel': 'Cancel',
  'company.company_book_meeting_modal.cannot_meet': 'You cannot meet any contacts at this company',
  'company.company_book_meeting_modal.meet': 'Meet',
  'company.company_book_meeting_modal.more': 'More',
  'company.company_book_meeting_modal.next': 'Next →',
  'company.company_book_meeting_modal.other_contacts': 'Other contacts',
  'company.company_book_meeting_modal.recommended_contacts': 'Recommended',
  'company.company_book_meeting_modal.select_contact': 'Select a contact to meet:',
  'company.company_book_meeting_modal.title': 'Book meeting',
  'company.company_preview_modal.activities': 'Activities & interests',
  'company.company_preview_modal.book_meeting': 'Book meeting',
  'company.company_preview_modal.company_profile': 'Company profile',
  'company.company_preview_modal.people': 'People',
  'company.company_preview_modal.read_more': 'Read more',
  'company.company_preview_modal.title': '{{ company_display_name }}',
  'company.company_preview_modal.view_people': 'View all contacts →',
  'company.company_preview_modal.view_profile_page': 'View profile page',
  'company.drop_business_card.agreement': 'I hereby give permission for my <strong> name, job title and email address </strong> to be shared with {{ company_display_name }}',
  'company.drop_business_card.cancel': 'Cancel',
  'company.drop_business_card.disclaimer': 'I understand that my information will be stored by {{ company_display_name }} and I must contact {{ company_display_name }} to change my communication preferences.',
  'company.drop_business_card.header': 'Share your contact information',
  'company.drop_business_card.share': 'I agree',
  'company.drop_business_card.share_success.done': 'Done',
  'company.drop_business_card.share_success.message': 'Your name, job title, email address and phone number have been shared with {{ company_display_name }}',
  'company.drop_business_card.share_success.title': 'Details shared',
  'company_card.view_company': 'View company',
  'concierge_modal.info_text': 'Contact the event networking concierge team for meeting-related enquiries and support.',
  'concierge_modal.link_text': 'Need help?',
  'concierge_modal.whatsapp_button': 'Chat on WhatsApp',

  // Conference sessions
  'conference_sessions.ask_a_question.box_closing': 'This box will automatically close.',
  'conference_sessions.ask_a_question.buttons.ask': 'Ask a question',
  'conference_sessions.ask_a_question.buttons.cancel': 'Cancel',
  'conference_sessions.ask_a_question.buttons.send': 'Send',
  'conference_sessions.ask_a_question.description': 'The session moderator will review your question',
  'conference_sessions.ask_a_question.header': 'Ask a question',
  'conference_sessions.ask_a_question.placeholder': 'My question...',
  'conference_sessions.ask_a_question.question_submitted': 'Your question has been submitted.',
  'conference_sessions.preview_session.speakers': 'Speakers',
  'conference_sessions.preview_session.title': 'Conference session',
  'conference_sessions.row.buttons.add_to_schedule': 'Add to plan',
  'conference_sessions.row.buttons.join_session': 'Join Session',
  'conference_sessions.row.buttons.more': 'More',
  'conference_sessions.row.buttons.preview_session': 'Preview',
  'conference_sessions.row.buttons.remove_from_schedule': 'Remove from schedule',
  'conference_sessions.row.buttons.schedule_clash': 'Schedule Clash',
  'conference_sessions.row.buttons.booking_capacity_full': 'Capacity full',
  'conference_sessions.row.buttons.watch_now': 'Watch now',
  'conference_sessions.row.session': 'Session',
  'conference_sessions.row.sponsored_by': 'Sponsored by',
  'conference_sessions.row.starting_soon': 'Starting soon',

  'conversations.accepted_by': 'Meeting Invitation accepted by {{ sender }}',
  'conversations.buttons.send_invite': 'Send Invite',
  'conversations.cancelled_by': 'Meeting Invitation cancelled by {{ sender }}',
  'conversations.chips.cancelled': 'Meeting cancelled',
  'conversations.chips.colleague_added': 'Colleague added',
  'conversations.chips.confirmed': 'Meeting confirmed',
  'conversations.chips.declined': 'Invitation declined',
  'conversations.chips.forwarded': 'Invitation forwarded',
  'conversations.chips.new_invite': 'Meeting invitation',
  'conversations.chips.updated': 'Meeting updated',
  'conversations.chips.withdrawn': 'Invitation withdrawn',
  'conversations.colleague_added': '{{ added_by }} added {{ new_colleague }} to the meeting.',
  'conversations.declined_by': 'Meeting Invitation declined by {{ sender }}',
  'conversations.forwarded_to': 'Meeting Invitation forwarded to {{ recipient }}',
  'conversations.invitation_from': 'Meeting Invitation from {{ sender }}',
  'conversations.view_meeting': 'View Meeting',
  'conversations.withdrawn_by': 'Meeting Invitation withdrawn by {{ sender }}',
  'countdown.days': 'days',
  'countdown.hours': 'hours',
  'countdown.minutes': 'minutes',
  'countdown.seconds': 'seconds',
  'crm.communicationPreference.channels.email': 'Notification and reminder emails',
  'crm.communicationPreference.channels.phone': 'Support telephone communication',
  'crm.communicationPreference.channels.sms': 'Notification and reminder text messages',
  'crm.communicationPreference.name': 'Preferences',
  'crm.company.bio': 'Company description',
  'crm.company.displayName': 'Display name',
  'crm.company.legalName': 'Legal name',
  'crm.company.logo': 'Company logo',
  'crm.company.officeLocation': 'Location',
  'crm.company.officeLocations': 'Locations',
  'crm.company.socials': 'Social media',
  'crm.company.tagline': 'Short introduction',
  'crm.company.websites': 'Websites',
  'crm.companyOfficeLocation.address': 'Address',
  'crm.companyOfficeLocation.country': 'Country',
  'crm.contact.avatar': 'Profile photo',
  'crm.contact.bio': 'About me',
  'crm.contact.country': 'Country',
  'crm.contact.email': 'Email',
  'crm.contact.firstName': 'First name',
  'crm.contact.fullName': 'Name',
  'crm.contact.jobTitle': 'Job title',
  'crm.contact.lastName': 'Last name',
  'crm.contact.lookingFor': 'Looking for',
  'crm.contact.managedBy': 'Profile managers',
  'crm.contact.optouts': 'Optouts',
  'crm.contact.timezone': 'Time zone',
  'crm.contact.title': 'Title',
  'crm.link.type.website': 'Website',
  'crm.link.url': 'Url',
  'crm.phone.types.mobile': 'Mobile phone',
  'crm.phone.types.office': 'Office phone',
  'directory.default_search_name': '{{ date }} Search results',
  'directory.saved_search_success': 'Search saved successfully.',
  'directory.updated_search_success': 'Search updated successfully.',
  'directory.edit_search': 'Edit this search',
  'directory.export_wishlist': 'Download',
  'directory.filters.apply': 'Apply',
  'directory.filters.clearAll': 'Clear all',
  'directory.filters.close': 'Close filters',
  'directory.filters.filters': 'Sort & filter',
  'directory.filters.open': 'Sort & filter',
  'directory.filters.primary.select_one_option': 'Select <strong>one</strong> option:',
  'directory.filters.reset': 'Reset',
  'directory.filters.save': 'Save',
  'directory.noFavourites.message': "You don't have any favourites yet.",
  'directory.noFavourites.title': 'No favourites',
  'directory.noRecommendations.message': 'No results to display.',
  'directory.noRecommendations.title': 'No results',
  'directory.noResults.message': "We couldn't find any results, try refining your query",
  'directory.noResults.title': 'No results',
  'directory.noSavedSearches.message': 'No saved searches. Start <a href="?initialTab=search">searching ...</a>',
  'directory.noSavedSearches.title': 'No searches',
  'directory.saved_search_card_error': 'Something went wrong, please refresh your browser',
  'frequency.daily': 'Daily',
  'frequency.weekly': 'Weekly',
  'frequency.never': 'Never',
  'directory.notification_frequency': 'Notify me about new search results',
  'directory.search_last_run': 'Last run: {{ date }}',
  'directory.pagination.load_more': 'Load more results',
  'directory.save_search': 'Save search',
  'directory.search_name': 'Search name',
  'directory.text_search': 'Search: {{ value }}',
  'directory.search': 'Back to searches',
  'directory.initial_search': 'Initial: {{ value }}',
  'drop_image.buttons.cancel': 'Cancel',
  'drop_image.buttons.clear': 'Clear',
  'drop_image.buttons.remove': 'Remove',
  'drop_image.buttons.submit': 'Submit',
  'drop_image.preview_text': 'Preview of {{ image_name }}',
  'drop_image.text': 'Drag and drop or click here',
  'event_editions.card.coming_soon': 'Coming soon',
  'event_editions.card.event_closed': 'Event closed',
  'event_editions.card.launch': 'Launch event',
  'event_editions.card.notify_me': 'Notify me',
  'event_editions.card.reg_done': 'You have registered for this event',
  'event_editions.card.reg_open': 'Registration now open',
  'event_editions.card.reg_soon': 'Registration coming soon',
  'event_editions.card.register': 'Register now!',
  'expanded_contact.buttons.accept_invite': 'Accept',
  'expanded_contact.buttons.book_meeting': 'Book meeting',
  'expanded_contact.buttons.decline_invite': 'Decline',
  'expanded_contact.buttons.forward_to_colleague': 'Forward',
  'expanded_contact.buttons.withdraw_invite': 'Withdraw',
  'expanded_contact.meeting.addColleague.button': 'Add colleagues',
  'expanded_contact.meeting.addColleague.noColleagues': 'no colleagues are available',
  'expanded_contact.meeting.addColleague.subheader': 'Add colleagues',
  'expanded_contact.meeting.back': 'Back to meeting details',
  'expanded_contact.meeting.cancel.button': 'Send cancellation',
  'expanded_contact.meeting.cancel.subheader': "I'm no longer able to attend this meeting",
  'expanded_contact.meeting.cancelledHeader': 'Meeting cancelled',
  'expanded_contact.meeting.contactNumber': 'Mobile number: ',
  'expanded_contact.meeting.forwardToColleague.button': 'Select colleague',
  'expanded_contact.meeting.forwardToColleague.subheader': 'Forward meeting to a colleague',
  'expanded_contact.meeting.informationHeader': 'Meeting information',
  'expanded_contact.meeting.informationSubheader': 'We will send a text message reminder 10 minutes before the meeting starts.',
  'expanded_contact.meeting.inviteColleagues.button': 'Invite colleagues',
  'expanded_contact.meeting.inviteColleagues.subheader': 'Forward meeting to a colleague',
  'expanded_contact.meeting.missingContactNumber': 'Please add a mobile number',
  'expanded_contact.meeting.participants': 'Participants',
  'expanded_contact.meeting.reschedule.button': 'Reschedule meeting',
  'expanded_contact.meeting.reschedule.subheader': 'Change meeting date, time or location',
  'expanded_contact.show.about.company.title': 'Company info',
  'expanded_contact.show.about.contact.country': 'Country',
  'expanded_contact.show.about.contact.looking_for': 'Looking for',
  'expanded_contact.show.about.contact.title': 'About me',
  'expanded_contact.show.colleagues': 'Colleagues',
  'expanded_contact.show.errors.cannot_load': "Couldn't load contact information.",
  'expanded_contact.show.other_contacts': 'Other contacts at {{ company_display_name }}',
  'expanded_contact.show.respond': 'Respond',
  'expanded_contact.show.send_invite': 'Send invite',
  'expanded_contact.show.view_invite': 'View invite',
  'expanded_contact.show.view_meeting': 'View meeting',
  'expanded_contact.show.view_profile': 'View profile',

  // Exclusive events
  'exclusive_events.no_description': 'There is no description for this event.',
  'exclusive_events.row.buttons.booking_capacity_full': 'Capacity full',
  'exclusive_events.row.buttons.booking_add_to_schedule': 'Add to schedule',
  'exclusive_events.row.buttons.booking_schedule_clash': 'Schedule Clash',
  'exclusive_events.row.buttons.booking_remove_from_schedule': 'Remove from schedule',

  'filter_modal.cancel': 'Cancel',
  'filter_modal.save_update': 'Save & update',
  'form.image.changeTip': '<a href=\'#\'>Click to change </a> or drag and drop. <br/>',
  'form.image.maxSize': 'Maximum file size 5MB',
  'form.image.tip': '<a href=\'#\'>Click to upload</a> or drag and drop. <br/>',
  'form.image.unsupportedFileType': 'Invalid file type.  Use jpg, jpeg, png or webp',
  'form.phone.placeholder': 'Enter your phone number',
  'form.video.tip': '<a href=\'#\'>Click to upload</a> or drag and drop. <br/>',
  'form.unsupportedFileTypePdf': 'Unsupported file type.',
  'forms.select_all': '(all)',
  'forms.toggle.on': 'On',
  'forms.toggle.off': 'Off',
  'general.unknown_error': 'Sorry something has gone wrong. Please refresh your browser and try again',

  // hubs general page
  'hubs.header.stand': 'Stand: {{ stand }}',
  'hubs.companyProfile': 'company profile',
  'hubs.definitions.confirmedMeetings': 'Confirmed meetings',
  'hubs.definitions.receivedPendingInvites': 'Open received meeting invitations',
  'hubs.definitions.sentPendingInvites': 'Open sent meeting invitations',
  'hubs.definitions.sentInvites': 'Sent meeting invitations (all statuses)',

  // hubs event profile
  'hubs.eventProfile.activity.favourited': 'Favourited',
  'hubs.eventProfile.activity.hides': 'Hides',
  'hubs.eventProfile.activity.marketplacePerformance': 'Performance',
  'hubs.eventProfile.activity.marketplacePerformanceScore': 'Top {{ score }}%',
  'hubs.eventProfile.activity.marketplaceViews': 'Views',
  'hubs.eventProfile.activity.noData': 'Not enough data',
  'hubs.eventProfile.activity.performance': 'Profile performance',
  'hubs.eventProfile.activity.profileViews': 'Profile views',
  'hubs.eventProfile.catalogueActive': 'Entries active',
  'hubs.eventProfile.catalogueDescription': 'Use the catalogue directories to promote your products and services.',
  'hubs.eventProfile.catalogueTitle': 'Catalogue entries',
  'hubs.eventProfile.countPlays': '{{ count }} plays',
  'hubs.eventProfile.description': 'Manage your profile and promote your presence at the event.',
  'hubs.eventProfile.emptyList': 'No items listed. Manage items in your <a href="#/my-account/company-profile">company profile <i class="fa "></i></a>',
  'hubs.eventProfile.link': 'Event profile',
  'hubs.eventProfile.marketplaceActive': 'Active',
  'hubs.eventProfile.marketplaceDescription': 'Promote your products and services through the marketplace directories.',
  'hubs.eventProfile.marketplaceTitle': 'Marketplace',
  'hubs.eventProfile.performanceScore': 'Top {{ score }}%',
  'hubs.eventProfile.title': 'Event profile',
  'hubs.eventProfile.videos': 'Videos',
  'hubs.eventProfile.videosActive': 'Videos active',
  'hubs.eventProfile.videosDescription': 'Manage your promotional videos.',
  'hubs.eventProfile.videosTitle': 'Videos',
  'hubs.eventProfile.viewsPerDay': 'Profile views per day',

  // hubs insights
  'hubs.insights.activity': 'Company profile performance',
  'hubs.insights.activity.favourited': 'Added to favourites',
  'hubs.insights.activity.hides': 'Added to hides',
  'hubs.insights.activity.profilePreviews': 'Profile previews',
  'hubs.insights.activity.profileViews': 'Profile page views',
  'hubs.insights.activity.noData': 'not enough data to show',
  'hubs.insights.description': 'Review your event performance.',
  'hubs.insights.meetings': 'Company meeting performance',
  'hubs.insights.link': 'Insights',
  'hubs.insights.title': 'Insights',

  // hubs leads
  'hubs.leads.businessCardDropTable': 'Dropped business cards',
  'hubs.leads.businessCardDropTableDescription': 'The following people have dropped their business cards on your company profile for follow up.',
  'hubs.leads.description': 'Access your leads and dropped business cards.',
  'hubs.leads.download': 'Download leads',
  'hubs.leads.downloadFormat': 'CSV / Excel',
  'hubs.leads.link': 'Leads',
  'hubs.leads.meetingsWith': 'Meetings with {{ people }}',
  'hubs.leads.noDroppedBusinessCards': 'No contacts have dropped their business cards yet.',
  'hubs.leads.numberOfBusinessCardDrops': 'Business cards',
  'hubs.leads.receivedInvitesFrom': 'Received invites from {{ people }}',
  'hubs.leads.sentInvitesTo': 'Sent invites to {{ people }}',
  'hubs.leads.title': 'Lead management',

  // purchase items
  'hubs.purchase_items.available': 'Available',
  'hubs.purchase_items.emptyList': 'No purchase items available.',
  'hubs.purchase_items.link': 'Upgrades & offers',
  'hubs.purchase_items.offers': 'Offers',
  'hubs.purchase_items.out_of_stock': 'Out of stock',
  'hubs.purchase_items.product_not_available': 'Product not available',
  'hubs.purchase_items.purchased': 'Purchased',
  'hubs.purchase_items.purchases': 'Purchases',
  'hubs.purchase_items.someAvailable': '{{ num }} available',
  'hubs.purchases.purchased_at': 'Purchased at: ',
  'hubs.purchases.purchased_by': 'Purchased by: {{ contact }}',

  // hubs meetings
  'hubs.meetings.confirmedMeetings': 'Meetings',
  'hubs.meetings.description': 'Review your company wide pre-scheduled meeting activity.',
  'hubs.meetings.link': 'Meetings',
  'hubs.meetings.MeetingsByDayTitle': 'Meetings by day',
  'hubs.meetings.MeetingsByLocationTitle': 'Meetings by location',
  'hubs.meetings.performance': 'Meeting performance',
  'hubs.meetings.performanceScore': 'Top {{score}}%',
  'hubs.meetings.receivedInvites': 'Pending invites',
  'hubs.meetings.sentInvites': 'Sent invitations',
  'hubs.meetings.teamHasMeetings': 'Has meetings',
  'hubs.meetings.teamHasPending': 'Has pending',
  'hubs.meetings.teamHasSent': 'Has sent',
  'hubs.meetings.teamTitle': 'Meeting activity',
  'hubs.meetings.title': 'Meetings',
  'hubs.stats.average': 'Average',
  'hubs.stats.belowAverage': 'Low',

  // hubs meetings team schedules
  'hubs.meetings.teamSchedules.unavailable': 'Unavailable',
  'hubs.meetings.teamSchedules': 'Combined team schedules',
  'hubs.meetings.teamSchedules.instructions': 'Select a day to view a contacts schedule for a given period.',
  'hubs.meetings.teamSchedules.bookings.empty': '{{sharer}} has no bookings during this period.',
  'hubs.meetings.teamSchedules.combinedBookings.empty': 'No bookings during this period.',

  // hubs team
  'hubs.team.activityChartSubtitle': 'last 60 days',
  'hubs.team.activityChartTitle': 'Team activity - ',
  'hubs.team.badgeTable': 'Event team',
  'hubs.team.contactUpdatedPlatformProfile': 'Profiles updated',
  'hubs.team.description': 'Monitor team activity and presence.',
  'hubs.team.lastLogin': 'last active',
  'hubs.team.link': 'Team',
  'hubs.team.noOtherBadges': 'None of you colleagues are registered for the event.',
  'hubs.team.profilesActivated': 'Profiles activated',
  'hubs.team.profilesHidden': 'Profiles hidden',
  'hubs.team.title': 'Event team',

  'hubs.tasks.link': 'Tasks',
  'hubs.tasks.title': 'Tasks',
  'hubs.tasks.taskProgress': 'Task progress',
  'hubs.tasks.pendingTasksTitle': 'Pending tasks',
  'hubs.tasks.pendingCompanyTasksDescription': 'The following tasks need to be completed by a representative of your company.',
  'hubs.tasks.pendingContactTasksDescription': 'The following tasks need to be completed by you.',
  'hubs.tasks.noPendingTasks': 'There are no tasks to complete.',
  'hubs.tasks.taskType.mandatory': 'Required',
  'hubs.tasks.taskType.important': 'Important',
  'hubs.tasks.taskType.optional': 'Optional',
  'hubs.tasks.completedTasksTitle': 'Completed tasks',
  'hubs.tasks.completedTasksDescription': 'The following tasks have been completed.',
  'hubs.tasks.nocompletedTasks': 'You have no completed tasks.',
  'hubs.tasks.start': 'Start',
  'hubs.tasks.expired': 'Expired',
  'hubs.tasks.view': 'View',
  'hubs.tasks.modal.title': 'Task details',
  'hubs.tasks.modal.cancel': 'Cancel',
  'hubs.tasks.modal.markAsRead': 'Mark as read',
  'hubs.tasks.modal.submit': 'Submit',
  'hubs.tasks.modal.close': 'Close',
  'hubs.tasks.completed_by': 'Completed by {{ responder }} @ {{ when }}',
  'hubs.tasks.file_submitted': 'You have already uploaded a file',
  'hubs.tasks.file_download': 'Download file',
  'hubs.tasks.user_file_download': 'Download file',
  'hubs.tasks.capacity_remaining': '{{ capacity_remaining }} remaining',
  'hubs.tasks.capacity_remaining_zero': 'Out of stock',

  'inbox.header': 'Inbox',
  'inbox.invites.buttons.accept': 'Accept meeting',
  'inbox.invites.buttons.askQuestion': 'Message',
  'inbox.invites.buttons.decline': 'Decline',
  'inbox.invites.buttons.forward': 'Forward',
  'inbox.invites.buttons.moreOptions': 'More options ...',
  'inbox.invites.buttons.next_invite': 'Next Invitation',
  'inbox.invites.buttons.read_more': 'more',
  'inbox.invites.buttons.respond': 'Respond',
  'inbox.invites.buttons.show_less': 'show less',
  'inbox.invites.buttons.view': 'View invitation',
  'inbox.invites.buttons.viewProfile': 'View profile',
  'inbox.invites.buttons.withdraw': 'Withdraw',
  'inbox.invites.declinedByActiveUser': 'You declined this invite {{ declined_time }}',
  'inbox.invites.declinedByRecipient': '{{ declined_by }} declined this invite {{ declined_time }}',
  'inbox.invites.expiry.expired': 'Expired',
  'inbox.invites.expiry.expires': 'Expires',
  'inbox.invites.expiry.expiresSoon': 'You have meeting invitations expiring soon.',
  'inbox.invites.expiry.whenExpired': 'You have expired meeting invitations.',
  'inbox.invites.findPeople': 'Search',
  'inbox.invites.forwardedByActiveUser': 'You forwarded this invite to {{ forwardee }}',
  'inbox.invites.forwardedByColleague': '{{ forwarded_by }} forwarded this meeting invitation to you.',
  'inbox.invites.forwardedByRecipient': 'Forwarded to {{ forwardee }} by {{ forwarded_by }}',
  'inbox.invites.info.sent_message_concierge': "The meetings concierge team will contact this user if they don't respond soon",
  'inbox.invites.noResults.message': '<a href="{{ findPeopleUrl }}">Search</a> for new meeting opportunities.',
  'inbox.invites.noResults.title': 'No meeting invites',
  'inbox.invites.sort.newest': 'Most recent',
  'inbox.invites.sort.oldest': 'Oldest',
  'inbox.invites.sort.placeholder': 'Sort',
  'inbox.invites.statuses.accepted': 'Accepted',
  'inbox.invites.statuses.declined': 'Declined',
  'inbox.invites.statuses.expired': 'Expired',
  'inbox.invites.statuses.forwarded': 'Forwarded',
  'inbox.invites.statuses.pending': 'Awaiting reply',
  'inbox.invites.statuses.sent': 'Sent',
  'inbox.invites.statuses.unread': 'New message',
  'inbox.invites.statuses.withdrawn': 'Withdrawn',
  'inbox.invites.statusFilters.accepted': 'Accepted',
  'inbox.invites.statusFilters.declined': 'Declined',
  'inbox.invites.statusFilters.expired': 'Expired',
  'inbox.invites.statusFilters.forwarded': 'Forwarded',
  'inbox.invites.statusFilters.pending': 'Action required',
  'inbox.invites.statusFilters.sent': 'Sent',
  'inbox.invites.statusFilters.withdrawn': 'Withdrawn',
  'inbox.invites.whenReceived': 'Received',
  'inbox.invites.whenSent': 'Sent',
  'inbox.invites.withdrawnByActiveUser': 'You withdrew this invite {{ withdrawn_time }}',
  'inbox.invites.withdrawnBySender': '{{ withdrawn_by }} withdrew this invite {{ withdrawn_time }}',
  'inbox.loading': 'Loading...',
  'inbox.meetings.buttons.addColleagues': 'Invite colleagues',
  'inbox.meetings.buttons.cancel': 'Cancel meeting',
  'inbox.meetings.buttons.moreOptions': 'More options ...',
  'inbox.meetings.buttons.rescheduleMeeting': 'Reschedule meeting',
  'inbox.meetings.cancelledBy': '{{ cancelled_by }} cancelled this meeting {{ cancel_time }}',
  'inbox.meetings.cancelledByActiveUser': 'You cancelled this meeting {{ cancel_time }}',
  'inbox.meetings.activeContactColleagueMeeting': 'Your colleague {{ colleague_name }} already has a meeting booked with {{ contact_name }}',
  'inbox.meetings.previouslyCancelledByActiveUser': 'You previously cancelled a meeting with {{ contact_name }} {{ cancel_time }}',
  'inbox.meetings.filters.allDays': 'All',
  'inbox.meetings.filters.showCancelled': 'Show cancelled meetings',
  'inbox.meetings.meetingsToRate_one': 'You have one meeting to rate. <a href="#/meetings/{{ nextId }}/rate">Rate now</a>',
  'inbox.meetings.meetingsToRate_other': 'You have {{ count }} meetings to rate. <a href="#/meetings/{{ nextId }}/rate">Rate now</a>',
  'inbox.meetings.noResults.message': '<a href="{{ findPeopleUrl }}">Search</a> for new meeting opportunities.',
  'inbox.meetings.noResults.title': 'You have no meetings yet',
  'inbox.meetings.rate': 'Rate meeting',
  'inbox.meetings.statuses.cancelled': 'Cancelled',
  'inbox.meetings.statuses.confirmed': 'Confirmed meeting',
  'inbox.meetings.view': 'Meeting information',
  'inbox.messages.info.moreMessagesInThread': 'There are more messages in this conversation',
  'inbox.messages.info.newUnreadMessage': 'New chat message. <a href="{{url}}">Reply</a>',
  'inbox.messages.meetingInformation': 'Meeting information',
  'inbox.messages.noResults.message': 'You have no messages.',
  'inbox.messages.noResults.title': 'No messages',
  'inbox.messages.sort.newest': 'Most recent',
  'inbox.messages.sort.oldest': 'Oldest',
  'inbox.messages.statusFilters.all': 'All',
  'inbox.messages.statusFilters.unread': 'Unread',
  'inbox.messages.whenReceived': 'Received',
  'inbox.messages.whenSent': 'Sent',
  'inbox.nextPage': 'Next Page',
  'inbox.previousPage': 'Previous Page',
  'inbox.relationshipLabels.from': 'From:',
  'inbox.relationshipLabels.to': 'To:',
  'inbox.relationshipLabels.with': 'With:',
  'inbox.subtitle.meetings': 'Confirmed meetings',
  'inbox.subtitle.messages': 'Messages',
  'inbox.subtitle.null': ' ',
  'inbox.subtitle.receivedInvites': 'Received invites',
  'inbox.subtitle.sentInvites': 'Sent invites',
  'meeting.add_colleagues.buttons.add': 'Add Colleagues',
  'meeting.add_colleagues.buttons.cancel': 'Cancel',
  'meeting.add_colleagues.buttons.done': 'Done',
  'meeting.add_colleagues.buttons.invite_more': 'Invite more',
  'meeting.add_colleagues.header': 'Add colleagues',
  'meeting.add_colleagues.no_colleagues': 'No colleagues available to add',
  'meeting.add_colleagues.subheader': 'Add colleagues to your meeting with <strong>{{display_name}}, {{job_title}}</strong> at <a href="{{company_url}}">{{company_display_name}}</a>',
  'meeting.add_colleagues.success.message_one': "We've successfully added your colleague to this meeting.",
  'meeting.add_colleagues.success.message_other': "We've successfully added your colleagues to this meeting.",
  'meeting.add_colleagues.success.title_one': 'Colleague added',
  'meeting.add_colleagues.success.title_other': 'Colleagues added',
  'meeting.addColleague.button': 'Add colleagues',
  'meeting.addToCalendar': 'Add to calendar',
  'meeting.attendees': 'You and {{ displayName }}',
  'meeting.attendeesCount': '{{count}} people',
  'meeting.attendeesTitle': 'Participants',
  'meeting.back_to_meeting': 'Back to meeting',
  'meeting.cancel.button': 'Send cancellation',
  'meeting.cancel.cannotAttend': "I'm no longer able to attend this meeting",
  'meeting.cancel.keep': 'Keep Meeting',
  'meeting.cancel.messageLabel': 'Note',
  'meeting.cancel.messagePlaceholder': 'Unfortunately I have to cancel this meeting because ...',
  'meeting.cancel.onlyOrganiserCanCancel': 'Only the main organiser can cancel the meeting',
  'meeting.cancel.reasonLabel': 'Reason for cancellation',
  'meeting.cancel.reasonPlaceholder': 'Select option...',
  'meeting.cancel.subheader': 'Cancel your scheduled meeting with <strong> {{ displayName }}, {{ jobTitle }}</strong> at <a href="{{ companyUrl }}"> {{ companyDisplayName }} </a>.',
  'meeting.cancel.title': 'Cancel Meeting',
  'meeting.cancellation_success.message': 'You successfully cancelled your meeting with <strong>{{display_name}}, {{job_title}}</strong> at <a href="{{company_url}}">{{company_display_name}}</a>',
  'meeting.cancellation_success.title': 'Invitation declined',
  'meeting.cancelledBy': 'This meeting was cancelled by {{ cancelledBy_display_name }} {{cancelled_at_time}}',
  'meeting.close': 'Close',
  'meeting.countdown_minutes': 'minutes, ',
  'meeting.countdown_seconds': 'seconds',
  'meeting.errors.cannot_load': 'Cannot load meeting.',
  'meeting.finished': 'Meeting has finished.',
  'meeting.forward.button': 'Select colleague',
  'meeting.forward.subheader': 'Forward meeting to a colleague',
  'meeting.forward.title': 'Forward to colleague',
  'meeting.in_progress': 'Meeting in progress.',
  'meeting.join_now': 'Join now',
  'meeting.meeting_confirmed.invite_colleagues': 'Invite Colleagues',
  'meeting.meeting_confirmed.message': 'Your meeting partner has been notified.',
  'meeting.meeting_confirmed.next_invite': 'Next Invite',
  'meeting.meeting_confirmed.title': 'Meeting confirmed',
  'meeting.meeting_confirmed.view_meeting': 'View details',
  'meeting.meeting_starts_in_countdown': 'Meeting starts in',
  'meeting.meetingCancelled': 'Meeting Cancelled',
  'meeting.meetingConfirmed': 'Fully confirmed by all parties.',
  'meeting.mobileNumberLabel': 'Mobile Number:',
  'meeting.panelTitle.cancelled': 'Meeting Cancelled',
  'meeting.panelTitle.information': 'Meeting Information',
  'meeting.phoneNumberMissing': '<a href="{{ updatePhoneUrl }}">Please add a mobile number</a>',
  'meeting.rate_meeting.back_to_meeting': 'Back to meeting',
  'meeting.rate_meeting.buttons.cancel': 'Cancel',
  'meeting.rate_meeting.buttons.done': 'Done',
  'meeting.rate_meeting.buttons.next': 'Next',
  'meeting.rate_meeting.buttons.rate': 'Rate',
  'meeting.rate_meeting.buttons.save': 'Save feedback',
  'meeting.rate_meeting.errors.already_rated': 'This meeting has already been rated',
  'meeting.rate_meeting.fields.feedback.label': 'Meeting feedback (optional)',
  'meeting.rate_meeting.fields.meeting_did_not_happen.label': "Meeting didn't happen",
  'meeting.rate_meeting.fields.rating.label': 'Rate your meeting',
  'meeting.rate_meeting.header': 'Rate your meeting',
  'meeting.rate_meeting.subheader': 'How was your meeting with <strong>{{display_name}}, {{job_title}}</strong> from {{company_display_name}}?',
  'meeting.rate_meeting.success.title': 'Rating submitted',
  'meeting.reminder': 'We will send a text message reminder 10 minutes before the meeting starts.',
  'meeting.reschedule.back_to_meeting': 'Back to meeting',
  'meeting.reschedule.button': 'Reschedule Meeting',
  'meeting.reschedule.onlyOrganiserCanReschedule': 'Only the main organiser can reschedule the meeting',
  'meeting.reschedule.subheader': 'Change meeting date, time or location',
  'meeting.reschedule.title': 'Reschedule Meeting',
  'meeting.source_types.auto_accepted': 'Auto accepted',
  'meeting.source_types.auto_match': 'Automatch',
  'meeting.source_types.concierge_booked': 'Concierge',
  'meeting.source_types.walk_up': 'Onsite booking',
  'meeting.stand': 'Stand {{ stand_number }}',
  'meeting.startingTime': 'Meeting starts {{ time_until_meeting }}',
  'meeting.statuses.cancelled': 'Cancelled',
  'meeting.updatePhone': 'Update number',
  'meeting_invite.back_to_conversation': 'Back to conversation',
  'meeting_invite.decline.buttons.cancel': 'Cancel',
  'meeting_invite.decline.buttons.decline': 'Decline invitation',
  'meeting_invite.decline.buttons.done': 'Done',
  'meeting_invite.decline.errors.cannot_load': 'Cannot load invite.',
  'meeting_invite.decline.header': 'Decline meeting invitation',
  'meeting_invite.decline.inputs.message.label': 'Add a message',
  'meeting_invite.decline.inputs.reason.label': 'Reason for declining',
  'meeting_invite.decline.inputs.reason.placeholder': 'Select reason',
  'meeting_invite.decline.subheader': 'Decline this meeting invitation from <strong>{{display_name}}, {{job_title}}</strong> at <a href="{{company_url}}">{{company_display_name}}</a>',
  'meeting_invite.decline.success.message': 'You successfully declined a meeting invitation from <strong>{{display_name}}, {{job_title}}</strong> at <a href="{{company_url}}">{{company_display_name}}</a>',
  'meeting_invite.decline.success.title': 'Invitation declined',
  'meeting_invite.forward_invite.buttons.cancel': 'Keep invitation',
  'meeting_invite.forward_invite.buttons.done': 'Done',
  'meeting_invite.forward_invite.buttons.forward': 'Forward invitation',
  'meeting_invite.forward_invite.header': 'Forward to colleague',
  'meeting_invite.forward_invite.inputs.forward_to_colleague.label': 'Include a note for your colleague:',
  'meeting_invite.forward_invite.inputs.forward_to_colleague.placeholder': "I've received a meeting invitation that is more relevant to you...",
  'meeting_invite.forward_invite.subheader': 'Forward your meeting invitation from <strong>{{display_name}}, {{job_title}}</strong> at <a href="{{company_url}}">{{company_display_name}}</a> to a colleague',
  'meeting_invite.forward_invite.success.message': "We've successfully forwarded this meeting invitation to your colleague.",
  'meeting_invite.forward_invite.success.title': 'Invite forwarded to colleague',
  'meeting_invite.opt_in_meetings.accept': 'Yes, opt-in to networking',
  'meeting_invite.opt_in_meetings.decline': 'No, I do not want to use networking',
  'meeting_invite.opt_in_meetings.opt_in_meetings_text': 'Opt-in to networking to find people, schedule meetings and receive meeting invitations from other event contacts.',
  'meeting_invite.opt_in_meetings.previously_requested_opt_out': 'You have previously requested not to use the networking service.',
  'meeting_invite.opt_in_meetings.title': 'Networking opt-in',
  'meeting_invite.pending_invites.limit_reached.body': 'You will be able to send more invitations when your current meeting invites are accepted.',
  'meeting_invite.pending_invites.limit_reached.title': 'Invitation limit reached',
  'meeting_invite.pending_invites.remaining': '<i class="fa-solid fa-circle-info"></i> You\'ve used <strong>{{ invite_spent }} of {{ invite_credits }}</strong> pending meeting invitations.',
  'meeting_invite.send_invite.buttons.cancel': 'Cancel',
  'meeting_invite.send_invite.buttons.done': 'Done',
  'meeting_invite.send_invite.buttons.send': 'Send Invitation',
  'meeting_invite.send_invite.buttons.view_sent_invites': 'Sent invitations',
  'meeting_invite.send_invite.errors.cannot_send_invite': 'Cannot send invite to this contact.',
  'meeting_invite.send_invite.header': 'Create your meeting invitation',
  'meeting_invite.send_invite.message_placeholder': 'Hi, I would like to meet to discuss ...',
  'meeting_invite.send_invite.sub_text': 'Your meeting partner will confirm the meeting date and time.',
  'meeting_invite.send_invite.success.message': 'Your meeting partner will review your invitation and confirm a date, time and location based on your availability.',
  'meeting_invite.send_invite.success.title': 'Invitation sent',
  'meeting_invite.send_invite.success.update_availability': 'Update my availability',
  'meeting_invite.withdraw.buttons.cancel': 'Cancel',
  'meeting_invite.withdraw.buttons.done': 'Done',
  'meeting_invite.withdraw.buttons.withdraw': 'Withdraw invitation',
  'meeting_invite.withdraw.errors.cannot_load': 'Cannot load invite.',
  'meeting_invite.withdraw.header': 'Withdraw invitation',
  'meeting_invite.withdraw.inputs.message.label': 'Add a message',
  'meeting_invite.withdraw.inputs.reason.label': 'Reason for withdrawing',
  'meeting_invite.withdraw.inputs.reason.placeholder': 'Select reason',
  'meeting_invite.withdraw.subheader': 'Withdraw this meeting invitation from <strong>{{display_name}}, {{job_title}}</strong> at <a href="{{company_url}}">{{company_display_name}}</a>',
  'meeting_invite.withdraw.success.message': 'You successfully withdrew a meeting invitation for <strong>{{display_name}}, {{job_title}}</strong> at <a href="{{company_url}}">{{company_display_name}}</a>',
  'meeting_invite.withdraw.success.title': 'Invitation withdrawn',
  'meeting_scheduler.acceptInvitation': 'Accept invitation',
  'meeting_scheduler.buttons.back': 'Back',
  'meeting_scheduler.buttons.done': 'Done',
  'meeting_scheduler.buttons.next': 'Next',
  'meeting_scheduler.errors.invite_already_actioned': 'This invite has already been actioned. ',
  'meeting_scheduler.errors.invite_already_actioned_link': '<a href="#/contacts/{{ invite_counterpart_contact_id }}/conversation">View</a>',
  'meeting_scheduler.errors.no_available_slots': 'No meeting slots available.',
  'meeting_scheduler.floorplanClickForFull': 'Full page floorplan',
  'meeting_scheduler.meetingUpdatedTitle': 'Meeting updated',
  'meeting_scheduler.rescheduleMeeting': 'Reschedule meeting',
  'meeting_scheduler.standLocation': '{{ companyName }} stand {{ standNumberFull }}',
  'meeting_scheduler.step.date': 'Date',
  'meeting_scheduler.step.location': 'Location',
  'meeting_scheduler.step.time': 'Time',
  'meeting_scheduler.unavailable': 'Unavailable',
  'multi_links.add_link': 'Add link',
  'multi_links.address': 'eg. https://www.{address}.com/...',
  'multi_links.error': 'Please make sure the type and url are set.',
  'multi_links.please_select': 'Please select',
  'multi_links.title': 'Links',
  'multi_links.type': 'Type',
  'mux_video_upload.buttons.cancel': 'Cancel',
  'mux_video_upload.buttons.delete': 'Delete',
  'mux_video_upload.buttons.rename': 'Rename',
  'mux_video_upload.buttons.save': 'Save',
  'mux_video_upload.buttons.upload': 'Upload',
  'mux_video_upload.error_text': 'There was an error uploading your video, please try again',
  'mux_video_upload.input.label': 'Title',
  'mux_video_upload.success_text': 'Your file was successfully uploaded, it will be ready soon',
  'mux_video_upload.video_status.created': 'New',
  'mux_video_upload.video_status.deleted': 'Deleted',
  'mux_video_upload.video_status.errored': 'Errored',
  'mux_video_upload.video_status.new_video': 'Choose File and Upload',
  'mux_video_upload.video_status.processing': 'Processing',
  'mux_video_upload.video_status.ready': 'Ready',
  'mux_video_upload.video_status.removed': 'Removed',
  'mux_video_upload.video_status.upload-cancelled': 'Upload cancelled',
  'mux_video_upload.video_status.upload-failed': 'Upload failed',
  'mux_video_upload.video_status.upload_failed': 'Upload incomplete, please delete and try again',
  'mux_video_upload.video_status.uploading': 'Uploading',
  'mux_video_upload.video_status.waiting': 'Waiting',
  'my_schedule.bookings.share_schedule': 'Share schedule',
  'my_schedule.bookings.share_no_colleagues': 'No eligible colleagues found',
  'my_schedule.bookings.source_types.auto_accepted': 'Auto accepted',
  'my_schedule.bookings.source_types.auto_match': 'Automatch',
  'my_schedule.bookings.source_types.concierge_booked': 'Concierge',
  'my_schedule.bookings.source_types.walk_up': 'Onsite booking',
  'my_schedule.bookings.table_number': '(table {{ number }})',
  'my_schedule.other_bookings.buttons.add': 'Add',
  'my_schedule.other_bookings.buttons.addOther': 'Other',
  'my_schedule.other_bookings.buttons.cancel': 'Cancel',
  'my_schedule.other_bookings.buttons.delete': 'Delete',
  'my_schedule.other_bookings.buttons.no_undos': 'This action cannot be undone.',
  'my_schedule.other_bookings.buttons.update': 'Update',
  'my_schedule.other_bookings.fields.description.label': 'Details',
  'my_schedule.other_bookings.fields.description.placeholder': 'Lunch with supplier',
  'my_schedule.other_bookings.fields.end_datetime.label': 'End',
  'my_schedule.other_bookings.fields.start_datetime.label': 'Start',
  'my_schedule.other_bookings.header_add': 'Add',
  'my_schedule.other_bookings.header_update': 'Update',
  'my_schedule.other_bookings.sub_text': "Your status will show 'unavailable' during this time.",
  'needHelp.body': 'Contact the event concierge team.',
  'needHelp.dataPrivacy.body': 'Collected leads are subject to terms of usage and data privacy policy.',
  'needHelp.dataPrivacy.header': 'Data privacy',
  'needHelp.dataPrivacy.link': 'Privacy policy',
  'needHelp.header': 'Need help?',
  'needHelp.link': 'Contact us',
  'profile.account_settings.email': 'Email',
  'profile.account_settings.first_name': 'First name',
  'profile.account_settings.last_name': 'Last name',
  'profile.account_settings.salutation': 'Title',
  'profile.account_settings.title': 'Account settings',
  'profile.buttons.cancel': 'Cancel',
  'profile.buttons.edit': 'Edit',
  'profile.buttons.save': 'Save',
  'profile.communication_preferences.email': 'Notification and reminder emails',
  'profile.communication_preferences.phone': 'Support telephone communication',
  'profile.communication_preferences.sms': 'Notification and reminder text messages',
  'profile.communication_preferences.something_went_wrong': 'Oops - something went wrong, please try again later',
  'profile.communication_preferences.subheader': 'Manage your meetings programme preferences',
  'profile.communication_preferences.title': 'Communication preferences',
  'profile.demographics.subheader': 'Fill in this information to help other users find you in searches',
  'profile.demographics.title': 'Search information',
  'profile.forms.catalogueEntry.create.modalTitle': 'Add new {{ name, lowercase }}',
  'profile.forms.catalogueEntry.edit.modalTitle': 'Edit {{ name, lowercase }}',
  'profile.forms.image.create.heading': 'Add a new image to your product catalogue.',
  'profile.forms.image.create.modalTitle': 'Add image',
  'profile.forms.image.edit.heading': 'Change your image\'s title',
  'profile.forms.image.edit.modalTitle': 'Edit image',
  'profile.forms.image.progressMessage': 'Your image is uploading, please wait',
  'profile.forms.video.create.modalTitle': 'Upload video',
  'profile.forms.video.edit.modalTitle': 'Edit video',
  'profile.forms.video.progressMessage': 'Your video is uploading, please wait',
  'profile.groups.aboutMe.heading': 'About me',
  'profile.groups.accountAccess.heading': 'Account access',
  'profile.groups.accountAccess.subheading': 'These users can manage your profile, chat and meetings activity.',
  'profile.groups.catalogue.heading': '{{ plural }}',
  'profile.groups.companyDemographics.heading': 'Other information',
  'profile.groups.companyInformation.heading': 'Company information',
  'profile.groups.companyInformation.subheading': 'Manage your company profile',
  'profile.groups.contactInformation.heading': 'Contact information',
  'profile.groups.eventOptouts.heading': 'Event optouts',
  'profile.groups.password.heading': 'Account password',
  'profile.groups.password.lastChanged': 'Last changed <b> {{ lastChangedAt }} </b>',
  'profile.groups.password.subheading': 'Your password must be at least 8 characters long and include one uppercase letter, one number and one special character.',
  'profile.groups.searchInformation.heading': 'Other information',
  'profile.groups.team.heading': 'Team members <small>({{ count }} people)</small>',
  'profile.groups.timezone.heading': 'Time zone',
  'profile.groups.timezone.subheading': 'View event dates and times in your preferred time zone. <b>(Virtual/Online events only)</b>.',
  'profile.informationSharingNotification': 'Your contact information is <b>NOT</b> displayed on your public profile.',
  'profile.modals.previewCompanyCard.modalTitle': 'Preview your company card',
  'profile.modals.previewContactCard.': 'Your card preview',
  'profile.modals.previewContactCard.modalTitle': 'Your card preview',
  'profile.optouts.something_went_wrong': 'Please refresh the page and try again',
  'profile.optouts.subheader': 'Selecting these options will hide your profile and/or decline all meetings and other appointments.',
  'profile.optouts.title': 'Event optouts',
  'profile.optouts.type_state_findable_false': 'I am NOT visible in the portal.',
  'profile.optouts.type_state_findable_true': 'I am visible in the portal.',
  'profile.optouts.type_state_meetable_false': 'I am NOT available for meetings.',
  'profile.optouts.type_state_meetable_true': 'I am available for meetings.',
  'profile.pages.catalogue.header': 'Marketplace',
  'profile.pages.companyProfile.header': 'Company profile',
  'profile.pages.companyProfile.previewButton': 'Preview company profile',
  'profile.pages.manageContent.header': 'Manage content',
  'profile.pages.manageContent.warning': 'Event settings may limit the display of some content.',
  'profile.pages.myAccount.action': 'Open event',
  'profile.pages.myAccount.eventAndBadges': 'My events & badges',
  'profile.pages.myAccount.header': 'My Account Home',
  'profile.pages.myAccount.welcome': 'Hi {{ firstName }}, welcome to your account.',
  'profile.pages.notificationSettings.header': 'Notification settings',
  'profile.pages.passwordSettings.header': 'Password & settings',
  'profile.pages.purchases.header': 'Purchases',
  'profile.pages.purchases.receipt': 'Receipt',
  'profile.pages.profileInformation.header': 'My profile',
  'profile.pages.profileInformation.previewButton': 'Preview my profile',
  'profile.pages.team.header': 'Team',
  'profile.pageTitle': 'My account',
  'profile.rows.avatarModalTitle': 'Upload profile photo',
  'profile.rows.bio.bioPlaceholder': 'My job involves … ',
  'profile.rows.companyLocations.addModalTitle': 'Add location',
  'profile.rows.companyLocations.delete': 'Are you sure you want to delete this location?',
  'profile.rows.companyLocations.updateModalTitle': 'Edit location',
  'profile.rows.companyWebsites.removeWarning': 'Are you sure you want to remove this $t(crm.link.type.website, lowercase) from your profile?',
  'profile.rows.country.selectCountryPlaceholder': 'Where are you located?',
  'profile.rows.fullNameModalTitle': 'Edit name',
  'profile.rows.logo.logoModalTitle': 'Upload company logo',
  'profile.rows.lookingFor.inputLabel': 'What type of contacts, companies, products, or services are you interested in?',
  'profile.rows.lookingFor.inputPlaceholder': 'I\'m looking for ...',
  'profile.rows.lookingFor.label': 'I\'m looking for ...',
  'profile.rows.lookingFor.modalTitle': 'I\'m looking for ...',
  'profile.rows.password.changeMessage': 'Send a change password link to:',
  'profile.rows.password.label': 'Current password',
  'profile.rows.password.modalTitle': 'Change password',
  'profile.rows.password.passwordLinkSent': 'Password link sent',
  'profile.rows.password.successMessage': '<h4> Email sent </h4><p> We\'ve sent a password reset link to:<br/><a href="mailto:{{email}}"> {{ email }} </a></p><p>Your link will expire in 1 hour.</p>',
  'profile.rows.phone.deleteWarning': 'Delete this phone number from your profile?',
  'profile.rows.phone.info': 'We may contact you about meetings or important event updates via text message (SMS) or phone. Your phone number is not displayed on your public profile.',
  'profile.rows.socials.updateModalTitle': 'Edit socials',
  'profile.rows.websites.addModalTitle': 'Add website',
  'profile.rows.websites.updateModalTitle': 'Edit website',
  'profile.tables.catalogueEntry.confirmDelete': 'Are you sure you want to delete this {{ name, lowercase }}?',
  'profile.tables.catalogueEntry.emptyMessage': 'No items to display.',
  'profile.tables.imageGallery.addOne': 'Add',
  'profile.tables.imageGallery.confirmDelete': 'Are you sure you want to delete this image?',
  'profile.tables.imageGallery.count_one': '({{ count }} item)',
  'profile.tables.imageGallery.count_other': '({{ count }} items)',
  'profile.tables.imageGallery.emptyMessage': 'No items to display.',
  'profile.tables.imageGallery.title': 'Image gallery',
  'profile.tables.videos.addOne': 'Add',
  'profile.tables.videos.confirmDelete': 'Are you sure you want to delete this video?',
  'profile.tables.videos.count': '({{ count }} / {{ limit }})',
  'profile.tables.videos.emptyMessage': 'No items to display.',
  'profile.tables.videos.title': 'Profile videos',
  'profile.tips.edit.body': "Details used to verify your identity and the company you work for can't be changed. Contact information, preferences and some details can be edited, but we may ask you to verify your identity before saving updates.",
  'profile.tips.edit.title': 'Which details can be edited?',
  'profile.tips.preview.title': 'Card preview',
  'profile.tips.share.body': 'We only share your contact information with confirmed meeting partners.',
  'profile.tips.share.title': 'What info is shared with others?',
  'profile.action.logout': 'Logout',
  'profile_select.footer.contact_support': 'Contact Support',
  'profile_select.footer.multiple_profiles': 'Why do I have multiple profiles?',
  'profile_select.footer.return_to_list': 'Return to profile list',
  'profile_select.header.info': 'Click profile options to <b>connect, merge or assign</b> to a colleague.',
  'profile_select.header.subheader': 'There is more than one profile associated with',
  'profile_select.header.title': 'Switch profile',
  'profile_select.info.duplicate_profiles.description': 'You may have registered twice.',
  'profile_select.info.duplicate_profiles.solution_text': "Select 'merge' from the profile options menu and follow the steps to combine data into a single user profile. We recommend you connect and check which profile you would like to keep.",
  'profile_select.info.duplicate_profiles.title': 'There are duplicate profiles under my name.',
  'profile_select.info.i_see_colleagues.description': 'Your company may have registered multiple colleagues using a shared email address, e.g. info@.',
  'profile_select.info.i_see_colleagues.solution_text': 'Select link to colleague from profile options menu.',
  'profile_select.info.i_see_colleagues.title': 'I see the names of other colleagues from my company.',
  'profile_select.info.listed_different_company.description': 'You may be registered under, or representing multiple companies. If this is correct, you can keep and switch between multiple profiles and use this profile select screen to switch between profiles.  If this is incorrect:',
  'profile_select.info.listed_different_company.solution_text': "Select 'delete' from the profile options menu. This process cannot be undone, we recommend you connect and check profiles before deleting.",
  'profile_select.info.listed_different_company.title': "I'm listed under different company names.",
  'profile_select.info.solution': 'Solution:',
  'profile_select.info.title': 'Why do I have multiple profiles?',
  'profile_select.profile.last_used': 'Last used:',
  'profile_select.profile.never_logged_in': 'Never',
  'profile_select.primary_contact': 'Primary contact',
  'ui.add': 'Add',
  'ui.delete': 'Delete',
  'ui.edit': 'Edit',
  'ui.faq': 'Frequently asked questions',
  'ui.help': 'Help',
  'ui.language': 'Language',
  'ui.locked': 'Locked',
  'ui.new': 'New',
  'ui.noItemsToDisplay': 'No items to display.',
  'ui.notAdded': 'Not added',
  'ui.notSet': 'Not set',
  'ui.selectCountry': 'Select country',
  'ui.support': 'Support',
  'ui.switchProfile': 'Switch profile',
  'update_availability.buttons.cancel': 'Cancel',
  'update_availability.buttons.update': 'Update availability',
  'update_availability.header': 'Your availability',
  'update_availability.key.available': 'Available',
  'update_availability.key.unavailable': 'Unavailable',
  'update_availability.subheader': 'Set which days and times you will be attending the event.',
  'video_meeting.message_concierge.box_closing': 'This box will automatically close in 5 seconds.',
  'video_meeting.message_concierge.buttons.cancel': 'Cancel',
  'video_meeting.message_concierge.buttons.message_concierge': 'Message concierge',
  'video_meeting.message_concierge.buttons.send': 'Send',
  'video_meeting.message_concierge.connecting': 'connecting',
  'video_meeting.message_concierge.header': 'Ask the concierge for assistance',
  'video_meeting.message_concierge.last_seen': '(Last seen {{ last_seen }})',
  'video_meeting.message_concierge.message_sent': 'Your message has been sent to the concierge, watch the chat window for a response.',
  'video_meeting.message_concierge.online': 'online',
  'video_meeting.message_concierge.waiting_join': 'Waiting for user to join',
  chat_visible: 'Chat visible to all participants',
  'purchase_upgrade.agree_terms_link': 'terms and conditions',
  'purchase_upgrade.agree_terms_post': '.',
  'purchase_upgrade.agree_terms_pre': 'I agree to the',
  'purchase_upgrade.buttons.agree': 'I agree',
  'purchase_upgrade.buttons.back': 'Back',
  'purchase_upgrade.buttons.buy_now': 'Buy now',
  'purchase_upgrade.buttons.cancel': 'Cancel',
  'purchase_upgrade.buttons.finish': 'Finish',
  'purchase_upgrade.buttons.pay_now': 'Pay now',
  'purchase_upgrade.buttons.print_receipt': 'Print receipt',
  'purchase_upgrade.buttons.try_again': 'Try again',
  'purchase_upgrade.checkout_heading': 'Checkout',
  'purchase_upgrade.error_heading': 'Error',
  'purchase_upgrade.payment_complete.heading': 'Payment complete',
  'purchase_upgrade.payment_complete.message': 'Your order has been processed and applied to account.',
  'purchase_upgrade.terms.subtitle': 'Subtitle',
  'purchase_upgrade.terms.title': 'Terms and conditions',

  'meeting_invite.notification.confirmed_meeting': 'You have a <a href="{{hash}}">confirmed meeting</a> with this contact.',
  'meeting_invite.notification.invite_already_sent': '<a href="{{hash}}">Invite already sent</a> to this contact.',
  'meeting_invite.notification.invite_already_received': 'You have a new <a href="{{hash}}">meeting invitation</a> from this contact.',
  'meeting_invite.notification.invite_withdrew_by_ac': 'Invitation withdrawn {{ withdrawn_time }}.<a href="{{hash}}">View details</a>.',
  'meeting_invite.notification.meeting_cancelled_by': 'A previous meeting was cancelled.<a href="{{hash}}">View meeting</a>.',
  'meeting_invite.notification.meeting_with_contact_colleague': 'You have a <a href="{{hash}}">confirmed meeting</a> with this company.',
  'meeting_invite.notification.meeting_with_contact_declined': 'Your <a href="{{hash}}">meeting invitation</a> was declined.',
  'meeting_invite.notification.meeting_with_contact_declined_by_you': 'You declined a <a href="{{hash}}">meeting invitation</a> from this contact.',
  'meeting_invite.notification.colleague_meeting_intersect': 'Your colleague is meeting with this company.',
  'meeting_invite.notification.meeting_with_colleague_confirmed': 'Your colleague is meeting with this contact.',
  'meeting.notification.view_meeting': 'View meeting',

  // Registrations
  'registrations.form.questions.component.name.first_name': 'First name',
  'registrations.form.questions.component.name.last_name': 'Last name',
  'registrations.form.questions.component.name.first_name_placeholder': 'Enter first name',
  'registrations.form.questions.component.name.last_name_placeholder': 'Enter last name',
  'registrations.form.actions.submit': 'Submit',
  'registrations.form.actions.next': 'Next',
  'registrations.form.screens.thankyou.heading': '<strong>Congratulations {{ name }}</strong>, your {{ eventName }} registration is complete.',
  'registrations.form.screens.thankyou.sub_heading': 'We’ve send you a confirmation email.',
  'registrations.form.screens.not_active.description': 'This registration form is currently unavailable, please try again later.',
  'registrations.form.screens.closed.alert_message': 'Registration has closed.',
  'registrations.form.screens.coming_soon.alert_message': 'Registration opens soon.',
  'registrations.form.actions.send_login_link': 'Send a login link',
  'registrations.form.actions.change_email_address': 'Change email address',
  'registrations.form.actions.send_link_to_continue': 'Send a link to continue',
  'registrations.form.validation.email_already_registered_message': 'Your email address is already registered...',
  'registrations.form.validation.email_already_have_partial_registration_message': 'Your email address has a partial registration...',
  'registrations.form.buttons.login': 'Log in',
  'registrations.form.actions.resend_email': 'Resend email',
  'registrations.form.actions.resend_email.message': 'Email sent!',

  // Videos
  'video_card.more': 'More details',
  'video_card.view_detail': 'Video detail',
  'videoModal.buttons.view_company': 'View company',
};
