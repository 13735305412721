<template>
  <ul class="video-meeting__attendees">
    <TransitionGroup name="t-chat">
      <VideoMeetingAttendee
        v-for="attendee in attendees"
        :key="attendee.id"
        :attendee="attendee"
        :attendeeStatus="lookupAttendeeStatus(attendee)"
      />
    </TransitionGroup>
  </ul>
</template>
<script>
import _ from 'lodash';
import axios from 'axios';
import { meeting } from '../../../Frontend/store';
import VideoMeetingAttendee from './VideoMeetingAttendee.vue';

export default {
  name: 'VideoMeetingAttendees',
  props: {
    initialAttendees: {
      type: Array,
      default: () => [],
    },
    updateUrl: {
      type: String,
    },
  },
  components: { VideoMeetingAttendee },
  data() {
    return {
      attendees: this.initialAttendees,
      meeting,
    };
  },
  methods: {
    update() {
      axios.get(this.updateUrl)
        .then((response) => {
          this.attendees = response.data.attendees;
        }).catch(() => {
          // eslint-disable-next-line no-alert
          alert('Please refresh your browser');
        }).then(() => {
          this.updateSoon();
        });
    },
    updateSoon() {
      const wait = _.random(3000, 7000);
      setTimeout(this.update, wait);
    },
    lookupAttendeeStatus(attendee) {
      return this.meeting.attendee_status.find((att) => att.id === attendee.id);
    },
  },
  mounted() {
    this.updateSoon();
  },
};
</script>
