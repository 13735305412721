<template>
  <label
    class="bubble-filter"
    :class="{'bubble-filter--selected': modelValue === option.value}"
    v-for="option in options"
    :key="option.value"
  >
    {{ option.text }}
    <input
      style="opacity: 0; width: 1px; position: absolute;"
      v-bind="inputProps"
      :value="modelValue"
      :checked="modelValue === option.value"
      type="radio"
      @change="$emit('update:modelValue', option.value)"
    />
  </label>
  <ul class="frontend-text-input__error" v-if="errors && errors.length > 0">
    <li class="frontend-radio-input__error" v-for="error in errors" :key="error">
      {{ error }}
    </li>
  </ul>
  <div v-if="errorMessage" class="frontend-radio-input__error">
    {{ errorMessage }}
  </div>
</template>

<script>

export default {
  name: 'FrontendRadioInputs',
  emits: [
    'update:modelValue',
    'blur',
    'focus',
  ],
  props: {
    errors: {
      type: Array,
      default: () => [],
    },
    errorMessage: {
      type: String,
      default: null,
    },
    label: {
      type: String,
    },
    modelValue: {
      required: true,
    },
    name: {
      type: String,
    },
    options: {
      type: Array,
      required: true,
    },
    placeholder: {
      type: String,
    },
    required: {
      type: Boolean,
    },
  },
  computed: {
    inputProps() {
      return {
        name: this.name,
        required: this.required,
        value: this.modelValue,
      };
    },
  },
  methods: {
    update(event) {
      this.$emit('update:errors', []);
      this.$emit('update:modelValue', event.target.value);
    },
  },
};
</script>
