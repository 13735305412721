<template>
  <div class="profile-row">
    <div class="profile-row__label">
      {{ __('crm.company.logo') }}
      <FeBadge v-if="!data.logo" alert small />
    </div>
    <div class="profile-row__value" v-if="data.logo" >
      <FeImage
        class="profile-row__company-logo"
        :image="data.logo"
        format="logo"
        alt="logo"
        placeholderIcon="fa-exclamation-triangle"
      />
    </div>
    <div class="profile-row__value profile-row__value--invalid" v-else>
      {{ __('ui.notAdded') }}
    </div>
    <div class="profile-row__action">
      <button class="button button--link" @click="openModal">
        <i class="fa fa-edit"></i>
        {{ __('buttons.edit') }}
      </button>
    </div>
  </div>
  <ModalForm
    :form="{
      name: 'logo',
      method: 'patch',
      action: '/profile/company-logo',
    }"
    :modal="{
      title: __('profile.rows.logo.logoModalTitle'),
      titleIcon: 'far fa-image-polaroid-user'
    }"
    @success="update"
    ref="modal"
  >
    <FormImage
      name="logo"
      button="Upload image"
      :default="src"
    />
    <template #footer>
      <div class="button-group">
        <button type="button" class="button button--secondary" @click="closeModal">
          {{ __('buttons.cancel') }}
        </button>
        <FormSubmit> {{ __('buttons.save') }}</FormSubmit>
      </div>
    </template>
  </ModalForm>
</template>

<script>

export default {
  props: {
    logo: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      data: {
        logo: this.logo,
      },
    };
  },
  methods: {
    openModal() {
      this.$refs.modal.open();
    },
    closeModal() {
      this.$refs.modal.close();
    },
    update(data) {
      this.data = data;
      this.closeModal();
    },
  },
};

</script>;
